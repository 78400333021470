import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import SavingsIcon from '@mui/icons-material/Savings';
export const UpgradeAndTopUp = () => {
    const theme = useTheme();

  return (
    <Box 
    sx={{
        p:3,
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
       }}>
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} sx={{minHeight:80}}>
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                        <Typography variant='subtitle2' sx={{color:theme.palette.grey.main, mb:1}}>
                            Risparmia fino al 10% sulla tua spedizione
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                        <Button variant={'contained'} size='large'  sx={{p:2,borderRadius:5}} fullWidth component={Link} to={'/top-up'} color={'primary'} startIcon={<SavingsIcon />} >
                            Effettua una ricarica
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                        <Typography variant='subtitle2' sx={{color:theme.palette.grey.main, mb:1}}>
                            Hai una partita iva? Passa a Business!
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                        <Button variant={'contained'} size='large'  sx={{p:2,borderRadius:5}} fullWidth component={Link} to={'/upgrade'} color={'secondary'} startIcon={<VerifiedIcon />} >
                            Passa a Business
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box> 
  )
}
    