import { useNavigate, useLocation } from "react-router-dom";
import { quotePublicShipping } from 'features/admin/shipping/hooks/ShippingApi';
import { Packages } from 'components/Packages';
import { useTheme } from '@mui/material/styles';
import {GoogleMapsAutoComplete} from 'components/GoogleMapsAutoComplete'
import { getCapByCity } from 'features/admin/shipping/hooks/ShippingApi';
import { Helmet } from 'react-helmet';
import posthog from "posthog-js";
import {
  Button,
  Typography,
  Tooltip,
  Fade,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useValue } from 'context/ContextProvider'
import {useMutation} from '@tanstack/react-query'
import { PackageType } from "./PackageType";

export const ShippingModule = () => {
  const theme = useTheme();
  const [senderCapList, setSenderCapList] = useState([])
  const [recipientCapList, setRecipientCapList] = useState([])
  const location = useLocation();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_PUBLIC_URL + '/logos'
  const {
    state: { shippingError, newShipping},
    dispatch,
  } = useValue();

  useEffect(()=>{
    if(newShipping.senderAddress.cap !== '') setSenderCapList([])
  },[newShipping.senderAddress.cap])

  useEffect(()=>{
    if(newShipping.recipientAddress.cap !== '') setRecipientCapList([])
  },[newShipping.recipientAddress.cap])


  const {isLoading, isError, isSuccess, mutate, error} = useMutation(quotePublicShipping, {
    onMutate: () =>{
        dispatch({ type: 'START_LOADING' });
    },
    onSuccess: (data) => {
      dispatch({ type: 'CREATE_NEW_SHIPPING', payload: {...newShipping, shippingQuotes: data.quotes, courierQuotes: data.courierQuotes, message: data.message }})
      dispatch({ type: 'END_LOADING' })
      navigate('/results', {
        state: {
            previousUrl: location.pathname,
        },});

    },
    onSettled: () => {
      dispatch({ type: 'CLOSE_SHIPPING_MODULE' });
    },
    onError: (data)=> {
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: <>
          <div>{data.response.data.detail}</div></>
          },}) 
      dispatch({ type: 'END_LOADING' }) },
  });

   const {isLoading:capLoading,  mutate: capByCity} = useMutation(getCapByCity, {
    onMutate: () =>{
    },
    onSuccess: (data) => {
      setSenderCapList(data)
    },
    onError: (data)=> {
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: <div>{data.response.data.detail}</div>
          },}) 
     },
  });

  const {isLoading:capLoadingRecipient,  mutate: capByCityRecipient} = useMutation(getCapByCity, {
    onMutate: () =>{
    },
    onSuccess: (data) => {
      setRecipientCapList(data)
    },
    onError: (data)=> {
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: <div>{data.response.data.detail}</div>
          },}) 
     },
  });

const handleValidation = () => {
    let senderStateErr = false
    let senderAddressErr = false
    let recipientStateErr = false
    let recipientAddressErr = false
    if (newShipping.senderAddress.cap == null){
      senderAddressErr = true
    } else {
      senderAddressErr = false
    }
    if (newShipping.senderAddress.state == null){
      senderStateErr = true
    } else {
      senderStateErr = false
    }
    if (newShipping.recipientAddress.cap == null){
      recipientAddressErr = true
    } else {
      recipientAddressErr = false
    }
    if (newShipping.recipientAddress.state == null){
      recipientStateErr = true
    } else {
      recipientStateErr = false
    }
    dispatch({type: 'UPDATE_SHIPPING_ERROR', payload: {...shippingError ,senderState: senderStateErr, senderAddress: senderAddressErr, recipientState: recipientStateErr, recipientAddress: recipientAddressErr,}});
    if (!senderAddressErr && !senderStateErr && !recipientAddressErr && !recipientStateErr && !shippingError.length && !shippingError.width && !shippingError.weight && !shippingError.height){
      //spedizione valida
      return true
    } else {
      return false
    }
  }

   const handleQuote = () => {
    posthog.capture('Quota Spedizione', {
      stateFrom: newShipping.senderAddress.state,
      stateTo: newShipping.recipientAddress.state,
      packages: newShipping.shippingRows,
      isCod: newShipping.isCod,
      isInsurance: newShipping.isInsurance
    })
    if (handleValidation()){
      if( newShipping.recipientAddress.state === 'IT' || newShipping.senderAddress.state === 'IT'){
        if(newShipping.senderAddress.cap !== ''){
          if(newShipping.recipientAddress.cap !== ''){
            const list = newShipping.shippingRows;
            if (list[0].weight >0){
                dispatch({type: 'CREATE_NEW_SHIPPING', payload: {...newShipping ,shippingQuotes: [], message: ''}});
                mutate(newShipping);
            } else{
                dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                    open: true,
                    severity: 'error',
                    message: 'Inserire i colli della spedizione' ,
                    },
                });
            }
          } else {
            //suggestions
            dispatch({
              type: 'UPDATE_ALERT',
              payload: {
              open: true,
              severity: 'warning',
              message: 'Non è stato possibile recuperare il cap dalle informazioni fornite per il destinatario, inseriscilo manualmente' ,
              },
            });
            capByCityRecipient({city:newShipping.recipientAddress.city})
          }
        } else{
          dispatch({
            type: 'UPDATE_ALERT',
            payload: {
            open: true,
            severity: 'warning',
            message: 'Non è stato possibile recuperare il cap dalle informazioni fornite per il mittente, inseriscilo manualmente' ,
            },
          });
          capByCity({city:newShipping.senderAddress.city})
        }
      } else{
        dispatch({
            type: 'UPDATE_ALERT',
            payload: {
            open: true,
            severity: 'info',
            message: 'Non è possibile spedire da ' + newShipping.senderAddress.state + ' a ' + newShipping.recipientAddress.state + ' Selezionare italia in uno dei campi.' ,
            },
        });
      } 
    } 
  } 

  return (
      <Grid container spacing={2} sx={{p:2}}>
        <Helmet>
            <link rel="preload" href="assets/icons/ndr-logo-white.ico" as="image" />
        </Helmet>
        <Grid item xs={12} sm={6}>
          <GoogleMapsAutoComplete type={'cap'} isSender={true} />
           {senderCapList.length > 0 && newShipping.senderAddress.state === 'IT' && (
          <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 2000 }}
              title={<div style={{ whiteSpace: 'pre-line' }}>
                {senderCapList.length > 0 && (senderCapList.map((cap, index)=>{return( (index > 0 ? '\n' : '') +
                cap.name + ' ' + cap.province + ' ' + cap.cap
              )}))
                }</div>
            }
            >
            <Typography variant='subtitle1' sx={{color:theme.palette.grey.light}}>{'Cercavi ' + newShipping.senderAddress.city +  '? Ecco una lista di possibili cap.'}</Typography>
            </Tooltip>
            )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <GoogleMapsAutoComplete type={'cap'} isSender={false} />
           {recipientCapList.length > 0 && newShipping.recipientAddress.state === 'IT' && (
          <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 2000 }}
              title={<div style={{ whiteSpace: 'pre-line' }}>
                {recipientCapList.length > 0 && (recipientCapList.map((cap, index)=>{return( (index > 0 ? '\n' : '') +
                cap.name + ' ' + cap.province + ' ' + cap.cap
              )}))
                }</div>
            }
            >
            <Typography variant='subtitle1' sx={{color:theme.palette.grey.light}}>{'Cercavi ' + newShipping.recipientAddress.city +  '? Ecco una lista di possibili cap.'}</Typography>
            </Tooltip>
            )}
        </Grid>
        <Grid item xs={12} sx={{mb:1, display:'flex', alignItems:'center', justifyContent:{xs:'center',sm:'flex-start'}}}>
            <PackageType packageType={'box'}/>
            <PackageType packageType={'bag'}/>
            <PackageType packageType={'pallet'}/>
        </Grid>
        <Grid item xs={12} >
            <Packages/>
        </Grid>
        <Grid item xs={12}>
            <Button variant='contained' fullWidth size='large' onClick={handleQuote} sx={{p:2, borderRadius:5}} startIcon={
              <img srcSet={`${url}/ndr-logo-white-256.webp 256w, ${url}/ndr-logo-white-50.webp 50w`} src={`${url}/ndr-logo-white-256.webp`} alt="NDR Logo" height={154} width={256} style={{maxWidth:50}}/>}>
                    Quota Spedizione
            </Button>
        </Grid>
    </Grid>
  );
};
