import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useValue } from 'context/ContextProvider';

import { useTheme } from '@mui/material/styles';

import {
  FormControlLabel,
  FormControl,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Chip,
  ListItem,
  TextField,
  Select,
  Box,
  Typography,
  FormLabel,
  Container,
  Divider,
  Checkbox,
  Paper,
  Grid,
  Button,
  Link,
  IconButton,
  Tooltip,
  Fade
} from '@mui/material';


export const Pages = () => {
    const theme = useTheme();

  return (
    <Grid container spacing={1} >
        <Grid item xs={12} sx={{color:theme.palette.grey.light,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Typography variant='subtitle1' color={'inherit'}>
                Pagine
            </Typography>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/spedire-online'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Come Spedire
            </Link>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/come-imballare'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Come Imballare
            </Link>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/destinations'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Dove posso spedire
            </Link>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/cosa-posso-spedire'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
             Cosa posso spedire
            </Link>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/prices'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Tariffe
            </Link>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/tracking'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Tracking
            </Link>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/support'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Assistenza
            </Link>
        </Grid>
    </Grid>
  )
}
