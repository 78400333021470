import {
    FormControl,
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Grid,
    IconButton,
    Tooltip,
    Paper,
    Typography,
    Button,
    Box,
    Slide,
    Divider,
    Container
  } from '@mui/material';
  import { useState, useEffect, useRef} from 'react';
  import { LogoAndSocial } from 'features/footer/components/LogoAndSocial';
  import { Links } from 'features/footer/components/Links';
  import { Pages } from 'features/footer/components/Pages';
  import { Resources } from 'features/footer/components/Resources';
  import { useTheme } from '@mui/material/styles';
export const Footer = () => {
const theme = useTheme();
  return (
   <Box 
   sx={{minHeight:'50vh', backgroundColor:theme.palette.backGround.dark}}>
   <Container>
        <Grid container spacing={2} sx={{pt:10}}>
            <Grid item xs={12} sm={12} md={3} sx={{display:'flex', alignItems:'flex-start', justifyContent:{xs:'center'}}}>
                <LogoAndSocial />
            </Grid>
            <Grid item xs={12} sm={4} md={3} sx={{display:'flex', alignItems:'flex-start', justifyContent:'center'}}>
                <Links />
            </Grid>
            <Grid item xs={12} sm={4} md={3} sx={{display:'flex', alignItems:'flex-start', justifyContent:'center'}}>
                <Pages />
            </Grid>
            <Grid item xs={12} sm={4} md={3} sx={{display:'flex', alignItems:'flex-start', justifyContent:'center'}}>
                <Resources />
            </Grid>
            <Grid item xs={12} sx={{display:'flex', justifyContent:'center', alignItems:'flex-end', mt:3}}>
                <Typography variant={'overline'} sx={{color:theme.palette.grey.main}}>
                    BY ANDREA P. @ NDR Software
                </Typography>
            </Grid>
        </Grid>
   </Container>
   </Box>
  )
}
