import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CookieRow } from './CookieRow';
import { useTheme } from '@mui/material/styles';
import posthog from 'posthog-js';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Link } from 'react-router-dom';
import {
  Button,
  Dialog,
  Paper,
  Switch,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import React, { useEffect, useState, } from 'react';

import { useValue } from 'context/ContextProvider'

export const AdvancedCookieBanner = () => {
const theme = useTheme();

const {
      state: { openCookieBanner, cookieSettings, openAdvancedCookieBanner},
      dispatch,
} = useValue();

  const handleClose = () => {
    dispatch({ type: 'CLOSE_ADVANCED_COOKIE_BANNER' });
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openAdvancedCookieBanner) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openAdvancedCookieBanner]);

  const savePreferences  = () =>{
    posthog.opt_in_capturing()

    handleClose()
  }

  const declineAllCookies = () => {
    dispatch({type:'UPDATE_COOKIE_SETTINGS', payload: {...cookieSettings, functional:false, experience:false, stats:false, marketing:false}})
  }

  const acceptAllCookies  = () =>{
    dispatch({type:'UPDATE_COOKIE_SETTINGS', payload: {...cookieSettings, functional:true, experience:true, stats:true, marketing:true}})
  }

  

  return (
      <Dialog
        open={openAdvancedCookieBanner}
        onClose={handleClose}
        scroll={'paper'}
        disableScrollLock={true}
        aria-labelledby="advanced-cookie-dialog"
        aria-describedby="advanced-cookie-dialog"
        sx={{maxHeight:600,}}
      >
        <DialogTitle id="scroll-dialog-title">
            <Box sx={{display:'flex', alignItems:'center',justifyContent:'space-between'}}>
                <IconButton aria-label="back" onClick={handleClose}>
                    <ArrowBackIosIcon />
                </IconButton>
                <Button variant='outlined' component={Link} to={'/cookie-policy'} size='small' color='secondary' sx={{p:1,borderRadius:5, maxWidth:250}} fullWidth >
                    Cookie Policy
                </Button>
            </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2} sx={{minWidth:500}}>
                <Grid item xs={12}>
                    <Typography variant={'h5'}  sx={{color:theme.palette.grey.main}} >
                        Le tue preferenze relative alla privacy
                    </Typography>
                    <Typography paragraph>
                        Questo pannello consente di gestire le preferenze sul trattamento dei tuoi dati personali.
                        Le tue preferenze possono essere aggiornate in ogni dayjso tramite il link dedicato a questo pannello.
                        Per non acconsentire alle pratiche di trattamento delineate di seguito, disabilita le opzioni specifiche o clicca su “Rifiuta tutto” e salva le tue scelte.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{display:'flex', alignItems:'center',justifyContent:'center', gap:1}}>
                    <Button variant='outlined' size='small' color='primary' onClick={declineAllCookies} startIcon={<ClearIcon/>} disabled={!cookieSettings.essentials} sx={{p:1,borderRadius:5, maxWidth:250}} fullWidth>
                        Rifiuta tutti
                    </Button>
                    <Button variant='contained' size='small' color='primary' onClick={acceptAllCookies} startIcon={<DoneIcon/>} disabled={!cookieSettings.essentials} sx={{p:1,borderRadius:5, maxWidth:250}} fullWidth>
                        Accetta tutti
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'}  sx={{color:theme.palette.grey.main}} >
                        Impostazioni del tuo consenso per le tecnologie di tracciamento
                    </Typography>
                    <Typography paragraph>
                    Gestione del consenso per le tecnologie di monitoraggio
Qui puoi modificare le tue impostazioni relative alle tecnologie di monitoraggio impiegate per i fini illustrati sotto. Consulta la cookie policy per approfondimenti su questi strumenti. Ricorda che negare il consenso può disabilitare le funzionalità ad esso associate.
                    </Typography>
                </Grid>
                    {Object.keys(cookieSettings).map((field,index) => {
                      return (
                          cookieSettings[field] !== null &&
                          <Grid item xs={12} key={index}>
                            <CookieRow field={field}/>
                          </Grid>
                              )
                    })}
                  </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' size='small' color='secondary' onClick={savePreferences} disabled={!cookieSettings.essentials} sx={{p:1,borderRadius:5, maxWidth:250}} fullWidth>
                Salva preferenze
            </Button>
        </DialogActions>
      </Dialog>
  );
}