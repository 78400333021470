import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useValue } from 'context/ContextProvider';

import { useTheme } from '@mui/material/styles';

import {
  FormControlLabel,
  FormControl,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Chip,
  ListItem,
  TextField,
  Select,
  Box,
  Typography,
  FormLabel,
  Container,
  Divider,
  Checkbox,
  Paper,
  Grid,
  Button,
  Link,
  IconButton,
  Tooltip,
  Fade
} from '@mui/material';


export const Links = () => {
    const theme = useTheme();

  return (
    <Grid container spacing={1} >
        <Grid item xs={12} sx={{color:theme.palette.grey.light,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}} >
            <Typography variant='subtitle1' color={'inherit'} >
              Ndr Spedizioni
            </Typography>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main, display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/privacy-policy'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Privacy Policy
            </Link>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/cookie-policy'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Cookie Policy
            </Link>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/note-legali'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Termini e condizioni
            </Link>
        </Grid>
        <Grid item xs={12} sx={{color:theme.palette.grey.main,display:'flex', justifyContent:{xs:'center', sm:'flex-start'}}}>
            <Link href={'/partners'} underline="none" variant='subtitle1' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
              Partners
            </Link>
        </Grid>
    </Grid>

  )
}
