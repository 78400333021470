import TextField from '@mui/material/TextField';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import React, {useEffect, useState, useMemo, useRef} from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import countryList from 'react-select-country-list';
import ReactCountryFlag from "react-country-flag"
import { geocodeByPlaceId, geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useValue } from 'context/ContextProvider';
import {
  Box,
  Typography,
  Grid,
} from '@mui/material';

const autocompleteService = { current: null };

export const GoogleMapsAutoComplete = ({ type, isSender}) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [stateValue, setStateValue] = useState({value:'IT', label:'Italia'})
  const [autoCompleteError, setAutoCompleteError] = useState(false)
  const countries = useMemo(() => countryList().setLabel('IT', 'Italia').getData(), [])

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );
    
  const {
    state: { newShipping, shippingError},
    dispatch,
  } = useValue();



  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  const handleResetState = () => {
    setStateValue({value:'IT', label:'Italia'})
  }

  useEffect(()=>{
    if(value!== null){
    geocodeByPlaceId(value?.place_id)
    .then(results => handleResults(results))
    .catch(error => console.error(error));

  /*   geocodeByAddress(isSender ? newShipping.senderAddress.state + ' ' + newShipping.senderAddress.cap + ' ' + newShipping.senderAddress.city : '')
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) =>
    isSender ? dispatch({type: 'CREATE_NEW_SHIPPING', payload:{...newShipping, senderAddress: {...newShipping.senderAddress, lat: lat, lng: lng}}}) : dispatch({type: 'CREATE_NEW_SHIPPING', payload:{...newShipping, recipientAddress: {...newShipping.recipientAddress, lat: lat, lng: lng}}})
  ); */
  }
  },[value])

  useEffect(() => {
    const handleScriptLoad = () => {
      // The Google Maps script is ready to use
      // Perform any operations that depend on the Google Maps API
      let active = true;
      if (!autocompleteService.current && window.google) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }
      if (!autocompleteService.current) {
        return undefined;
      }
  
      if (inputValue === '') {
        setOptions(value ? [value] : []);
        return undefined;
      }
  
      fetch({ input: inputValue, 
        componentRestrictions: {country:isSender ? newShipping.senderAddress.state !== "" ? newShipping.senderAddress.state : stateValue.value:
        newShipping.recipientAddress.state !== "" ? newShipping.recipientAddress.state : stateValue.value},
        fields: ["address_components"],
        types:  ['(regions)'] ['address']} , (results) => {
        if (active) {
          let newOptions = [];
  
          if (value) {
            newOptions = [value];
          }
  
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      });
  
      return () => {
        active = false;
      };
    };
  
    document.addEventListener('googleScriptLoaded', handleScriptLoad);
  
    // Check if the script is already loaded just in case the event has already fired
    if (window.google && window.google.maps) {
      handleScriptLoad();
    }
  
    return () => {
      document.removeEventListener('googleScriptLoaded', handleScriptLoad);
    };
  }, [value, inputValue, fetch]);

/*   useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, 
      componentRestrictions: {country: stateValue},
      fields: ["address_components"],
      types:  ['(regions)'] ['address']} , (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]); */

  const handleResults = (results) => {
    if(results[0].address_components.length > 0){
      let sender = {state: '', cap: '', city: '', streetNumber: '', address: '', province: ''}
      results[0].address_components.forEach(item => {
        if (item.types.includes('street_number')) {
        sender.streetNumber = item.long_name
        } else if (item.types.includes('route')) {
        sender.address = item.long_name
        } else if (item.types.includes('postal_code')) {
        sender.cap = item.long_name
        } else if (item.types.includes('administrative_area_level_2')) {
        sender.province = item.short_name          
        } else if (item.types.includes('country')) {
        sender.state = item.short_name
        } else if (item.types.includes('locality')||item.types.includes('postal_town')){
        sender.city = item.long_name
        }
        });
        if (isSender){
          dispatch({ type: 'CREATE_NEW_SHIPPING', payload: {...newShipping, shippingQuotes: [], senderAddress: sender}})
        } else {
          dispatch({ type: 'CREATE_NEW_SHIPPING', payload: {...newShipping, shippingQuotes: [], recipientAddress: sender}})
        }
    }
  }
  const handleGetValue= () => {
   let address = ''
    if (isSender){
      if (newShipping.senderAddress.cap !== null) {
        address = newShipping.senderAddress.cap;
      }
      if (newShipping.senderAddress.city !== null) {
        address = address + ' ' + newShipping.senderAddress.city;
      }
    } else {
      if (newShipping.recipientAddress.cap !== null) {
        address = newShipping.recipientAddress.cap;
      }
      if (newShipping.recipientAddress.city !== null) {
        address = address + ' ' + newShipping.recipientAddress.city;
      }
    }
    return address
  }
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{mb:1}}>
        <Autocomplete
        id={isSender ? 'senderGoogleMapsCap' : 'recipientGoogleMapsCap'}
        onChange={(event, newValue) => {
          setStateValue(newValue);
        }}
        options={countries}
        fullWidth
        size='large'
        autoHighlight
        disableClearable
        value={isSender ? newShipping.senderAddress.state !== "" ? 
                          {value: newShipping.senderAddress.state  ,label:countryList().getLabel(newShipping.senderAddress.state)} 
                            : stateValue :
                          newShipping.recipientAddress.state !== "" ?
                          {value: newShipping.recipientAddress.state ,label:countryList().getLabel(newShipping.recipientAddress.state)}:
                            stateValue }
        defaultValue={{ value: 'IT', label: 'Italia' }}
        getOptionLabel={(option) => option.label || ""}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}  {...props}>
            <ReactCountryFlag countryCode={option.value}  alt={`${option.label} Flag`} svg style={{ width: '30px', height: 'auto', borderRadius: '15%', }} /> {option.label} 
          </Box>
        )}
      /*   isOptionEqualToValue={(option, value) =>
          option.iso === value.iso
        } */
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={isSender ? shippingError.senderState : shippingError.recipientState}
            label={isSender ? 'Paese mittente' : 'Paese destinatario'}
            InputProps={{ ...params.InputProps,
              startAdornment:stateValue.value !== '' ?  <ReactCountryFlag countryCode={isSender? newShipping.senderAddress.state !== "" ? newShipping.senderAddress.state : stateValue.value :
                                                                                          newShipping.recipientAddress.state !== "" ? newShipping.recipientAddress.state : stateValue.value}  
                                                                      alt={'Flag'} svg style={{ width: '20px', height: 'auto', borderRadius: '15%', }} /> : '',
              sx: {  borderTopLeftRadius: 20, borderTopRightRadius:windowSize.innerWidth >= 1200 ? 0:20, borderBottomLeftRadius:  20, borderBottomRightRadius:windowSize.innerWidth >= 1200 ? 0:20}}}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            />
          )}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
        <Autocomplete
         id={isSender ? 'senderGoogleAutoComplete' : 'recipientGoogleAutoComplete'}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.description
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value === null ? handleGetValue() : value }
          noOptionsText={'Nessun cap, città o indirizzo trovato'}
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={type==='address' ? 'Indirizzo' : 'Cap, città o indirizzo completo'} fullWidth
            error={autoCompleteError}
            InputProps={{ ...params.InputProps,
              sx: { borderTopLeftRadius: windowSize.innerWidth >= 1200 ? 0:20, borderTopRightRadius: 20, borderBottomLeftRadius:  windowSize.innerWidth >= 1200 ? 0 : 20, borderBottomRightRadius: 20} }}
            />
          )}
          renderOption={(props, option) => {
            const matches =
              option.structured_formatting.main_text_matched_substrings || [];

            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            );

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      >
                        {part.text}
                      </Box>
                    ))}

                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
     </Grid>
    </Grid>
  );
}