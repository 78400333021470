import { Typography, Link} from "@mui/material"
import Shipping from 'assets/images/paper-plane.svg';
const url = process.env.REACT_APP_PUBLIC_URL + '/images'

function getSrcSet (image, ext){
    //find extension and take te extension
    return  `${url}/${image}/${image}-2560.${ext} 2560w, ${url}/${image}/${image}-1280.${ext} 1280w, ${url}/${image}/${image}-853.${ext} 853w, ${url}/${image}/${image}-640.${ext} 640w`
}

function getSrc (image, ext){
    //find extension and take te extension
    return  `${url}/${image}/${image}-2560.${ext}`
}

function getPlaceHolder (image, ext){
    //find extension and take te extension
    return  `${url}/${image}/${image}-blur.${ext}`
}

const cardboard = 'cardboard'
export const ServicesList =[
    {
        title: "Spedizioni Nazionali ed internazionali",
        enabled: true,
        body:   <Typography variant="subtitle1" paragraph>
                    Spedizioni nazionali ed internazionali in tutto il mondo per privati ed aziende. Creazione di soluzioni ad-hoc per ogni esigenza, con affidabilità, efficienza e risparmio al primo posto. Controlla tutte le <Link href={'/destinations'} underline="none" variant='subtitle1' color={'secondary'} sx={{':hover':{ color:'secondary'}}}>possibili destinazioni</Link>
                </Typography>,
        image: getSrc('van-moving','jpg'),
        imageSrcSet: getSrcSet('van-moving','jpg'),
        placeholder: getPlaceHolder('van-moving','jpg')
                
    },
    {
        title: "Import-Export",
        enabled: true,
        body:   <Typography variant="subtitle1" paragraph>
                    Esportazioni ed importazioni di documenti, pacchi, pallet o bancali da e verso tutto il mondo. Assistenza e documentazione doganale fanno parte delle nostre competenze, sapremo noi cosa fare. Sì, ma  <Link href={'/cosa-posso-spedire'} underline="none" variant='subtitle1' color={'secondary'} sx={{':hover':{ color:'secondary'}}}>Cosa posso spedire?</Link>
                </Typography>,
        image: getSrc('transpallet-boxes','jpg'),
        imageSrcSet: getSrcSet('transpallet-boxes','jpg'),
        placeholder: getPlaceHolder('transpallet-boxes','jpg')
    },
    {
        title: "Supporto fino alla consegna",
        enabled: true,
        body:   <Typography variant="subtitle1" paragraph>
                   Noi Monitoriamo la spedizione costantemente fino a consegna avvenuta e ti aggiorneremo con delle comode notifiche lo stato della tua spedizione cosi potrai tu stesso tenere d'occhio la tua merce <Link href={'/destinations'} underline="none" variant='subtitle1' color={'secondary'} sx={{':hover':{ color:'secondary'}}}>possibili destinazioni</Link>
                </Typography>,
        image: getSrc('delivery-man','jpg'),
        imageSrcSet: getSrcSet('delivery-man','jpg'),
        placeholder: getPlaceHolder('delivery-man','jpg')
    },
    {
        title: "Spedisci la tua valigia",
        enabled: false,
        body:   <Typography variant="subtitle1" paragraph>
                   <Link href={'/destinations'} underline="none" variant='subtitle1' color={'secondary'} sx={{':hover':{ color:'secondary'}}}>possibili destinazioni</Link>
                </Typography>,
        image: getSrc('cardboard','jpg'),
        imageSrcSet: getSrcSet('cardboard','jpg'),
        placeholder: getPlaceHolder('cardboard','jpg')
    },
    {
        title: "Risparmia fino al 10% con NDR Wallet",
        enabled: true,
        body:   <Typography variant="subtitle1" paragraph>
                   L' NDR Wallet è un portafoglio digitale progettato per semplificare e velocizzare le operazioni di spedizione per i nostri utenti. Con l' NDR Wallet, puoi caricare anticipatamente fondi nel tuo account ottenendo fino al 10% di credito extra... <Link href={'/risparmia-sulla-spedizione'} underline="none" variant='subtitle1' color={'secondary'} sx={{':hover':{ color:'secondary'}}}>inizia subito a risparmiare</Link>
                </Typography>,
        image: getSrc('top-up-and-save-10','webp'),
        imageSrcSet: getSrcSet('top-up-and-save-10','webp'),
        placeholder: getPlaceHolder('top-up-and-save-10','webp')
    },
    {
        title: "Differenza tra Spedizione Express e Servizio Postale",
        enabled: true,
        body:   <Typography variant="subtitle1" paragraph>
                   Quando si parla di "costo spedizione", è importante capire che non tutti i servizi di spedizione sono uguali... <Link href={'/risparmia-sulla-spedizione'} underline="none" variant='subtitle1' color={'secondary'} sx={{':hover':{ color:'secondary'}}}>continua la lettura</Link>
                </Typography>,
        image: getSrc('apples-to-oranges','webp'),
        imageSrcSet: getSrcSet('apples-to-oranges','webp'),
        placeholder: getPlaceHolder('apples-to-oranges','webp')
    },
    {
        title: "Guida step-by-step alla spedizione online",
        enabled: true,
        svgImage: Shipping,
        body:   <Typography variant="subtitle1" paragraph>
                   Spedire può sembrare complicato, ma con la nostra guida alla spedizione, diventerà un processo chiaro e gestibile... <Link href={'/come-spedire'} underline="none" variant='subtitle1' color={'secondary'} sx={{':hover':{ color:'secondary'}}}>scopri come effettuare la spedizione online</Link>
                </Typography>,
    },
    

]