import React, {useState, useRef, useEffect} from 'react'
import { useValue} from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import {useMutation, useQueryClient, useQuery} from '@tanstack/react-query'
import { verifyEmail,resendEmail } from 'features/auth/authApi';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Helmet } from 'react-helmet';
import {
    Grid,
    Typography,
    Button,
    Box,
    Container,
  } from '@mui/material';
import { Link , useParams, useNavigate} from 'react-router-dom';
export const EmailVerification = () => {   
    let {token}  = useParams();
    const theme = useTheme();
    const navigate = useNavigate()
    const {
        state: {loading, currentUser,colors,userError, userProfile},
        dispatch,
      } = useValue();

    const {mutate , isLoading, error} = useMutation(verifyEmail, {
        onMutate: () =>{
            dispatch({ type: 'START_LOADING' });
        },
        onError: (data)=> {
          dispatch({
            type: 'UPDATE_ALERT',
              payload: {
                open: true,
                severity: 'error',
                message:<div>{data.response.data.detail}</div>
              },}) 
          dispatch({ type: 'END_LOADING' }) },

        onSuccess: (data) => {
            navigate("/home");
          dispatch({
            type: 'UPDATE_ALERT',
              payload: {
                open: true,
                severity: 'success',
                message: data,
              },})   
        },
        onSettled: () => {
        dispatch({ type: 'END_LOADING' })
           //navigate to home with timer
           
          }
      });

      const {mutate : emailMutation , isLoading : emailIsLoading} = useMutation(resendEmail, {
        onMutate: () =>{
            dispatch({ type: 'START_LOADING' });
        },
        onError: (data)=> {
          dispatch({
            type: 'UPDATE_ALERT',
              payload: {
                open: true,
                severity: 'error',
                message:<div>{data.response.data.detail}</div>
              },}) 
          dispatch({ type: 'END_LOADING' }) },

        onSuccess: (data) => {
            navigate("/home");
          dispatch({
            type: 'UPDATE_ALERT',
              payload: {
                open: true,
                severity: 'success',
                message: data,
              },})   
        },
        onSettled: () => {
        dispatch({ type: 'END_LOADING' })
           //navigate to home with timer
           
          }
      });


 
    const handleVerifyEmail = () => {
        mutate({token: token});
    }

    const handleSendNewEmail = () => {
        emailMutation({token: token});
    }

    useEffect(()=>{
        if (isLoading && !loading){
            dispatch({ type: 'START_LOADING' })
        }
    },[loading, isLoading])

  return (
           
            <Container sx={{minHeight:'100vh', pb:10, pt:10,}}>
               <Helmet>
                    <title>Verifica Email - Conferma il Tuo Indirizzo | NDR Spedizioni</title>
                    <meta name='description' content='Benvenuto in NDR Spedizioni! Per completare la registrazione, verifica il tuo indirizzo email. Segui le istruzioni inviate alla tua casella di posta per assicurarti di ricevere tutte le comunicazioni importanti.' />
                </Helmet> 
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',gap:1, mb:5}}>
                            <Typography variant='h3' sx={{color:theme.palette.grey.main}}>
                               Verifica Email
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center',gap:1}}>
                            <Typography variant='button'>
                                Non hai ricevuto l' email?
                            </Typography>
                            <Button variant="contained" size={'small'} onClick={handleSendNewEmail} sx={{p:1, borderRadius:30}} color='primary'>
                                Invia nuova email
                            </Button>
                        </Box>
                    </Grid>
        
                    <Grid item xs={12} >
                        <Button variant='contained' size='large' sx={{p:2,borderRadius:5}} fullWidth startIcon={<VerifiedIcon fontSize='large'/>} onClick={handleVerifyEmail} color='secondary'
                                disabled={token === ''} >
                            Verifica Email
                        </Button>
                    </Grid>
                </Grid>
            </Container>
       
   )
}