import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useValue } from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import useCheckToken from 'features/auth/useCheckToken';
import {useMutation, useQueryClient, useQuery} from '@tanstack/react-query'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { updateNotificationState, deleteNotification, deleteAllNotification } from '../NotificationsApi';
import dayjs from 'dayjs' 
import relativeTime from 'dayjs/plugin/relativeTime';


import {
  Box,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Tooltip,
  Button
} from '@mui/material';
export const UserNotifications = ({ anchorUserNotifications, setAnchorUserNotifications, notifications }) => {
  const theme = useTheme();
  dayjs.extend(relativeTime);
  useCheckToken();
  const {
    state: {
      currentUser,
    },
    dispatch,
  } = useValue();
  const queryClient = useQueryClient();
  const handleCloseUserMenu = () => {
    setAnchorUserNotifications(null);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const {isLoading : deleteIsLoading, isError:deleteIsError, mutate:deleteMutation, error: deleteError} = useMutation(deleteNotification, {
    onMutate: () =>{
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['notifications', currentUser.id])
      navigate(data.data.url)
    },
    onError: (data)=> {
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: <div>{data.response.data.detail}</div>
          },}) 
       },
  });

  const {isLoading : deleteAllIsLoading, mutate:deleteAllMutation,} = useMutation(deleteAllNotification, {
    onMutate: () =>{
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['notifications', currentUser.id])
    },
    onError: (data)=> {
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: <div>{data.response.data.detail}</div>
          },}) 
       },
  });

  const {isLoading, isError, mutate: markAsRead, error} = useMutation(updateNotificationState, {
    onMutate: () =>{
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['notifications',currentUser.id])
      navigate(data.data.url)
    },
    onError: (data)=> {
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message:<div>{data.response.data.detail}</div>
          },}) 
       },
  });
  const handleClickNotification = (field) => {
    //mark notification as read
    markAsRead({id: field.id, state:'read'})
  }

  const handleDeleteNotification = (id) => {
    deleteMutation({id:id})
  }

  const handleDeleteAllNotifications = (id) => {
    deleteAllMutation({id:currentUser.id})
  }


  return (
    <Menu
    disableScrollLock={true}
    anchorEl={anchorUserNotifications}
    open={Boolean(anchorUserNotifications)}
    onClose={handleCloseUserMenu}
    onClick={handleCloseUserMenu}
     id="notifications-menu"
   PaperProps={{
     elevation: 0,
     sx: {
       overflow: 'auto',
       filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
       mt: 1.5,
       '& .MuiAvatar-root': {
         width: 32,
         height: 32,
         ml: -0.5,
         mr: 1,
       },
       '&:before': {
         content: '""',
         display: 'block',
         position: 'absolute',
         top: 0,
         right: 14,
         width: 10,
         height: 10,
         bgcolor: 'background.paper',
         transform: 'translateY(-50%) rotate(45deg)',
         zIndex: 0,
       },
     },
   }}
   transformOrigin={{ horizontal: 'right', vertical: 'top' }}
   anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {notifications.map((field, index) => {
          return (<Box sx={{display:'flex', alignItems:'center', gap:1, overflow:'clip', justifyContent:'space-between'}} key={index}>
          <MenuItem dense={true} onClick={() =>handleClickNotification(field)} sx={{color:field.state ==='read' ? theme.palette.grey.light : theme.palette.grey.main,overflow:'clip', width:'100%'}}>
            <Box sx={{display:'flex', alignItems:'center', gap:1}}>
              {field.type === 'shipping'&& (
                <LocalShippingIcon fontSize="small" />
              )}
               {field.type === 'payment'&& (
                <PaymentIcon fontSize="small" />
              )}
              {field.type === 'new_user'&& (
                <PersonAddIcon fontSize="small" />
              )}
              {field.type === 'new_shipping'&& (
                <SendIcon fontSize="small" />
              )}
              {field.type === 'support'&& (
                <LiveHelpIcon fontSize="small" />
              )}
              {field.type === 'info'&& (
                <InfoIcon fontSize="small" />
              )}
              {field.type === 'ticket'&& (
                <ConfirmationNumberIcon fontSize="small" />
              )}
              {field.type === 'partnership'&& (
                <HandshakeIcon fontSize="small" />
              )}
              {field.type === 'upgrade'&& (
                <VerifiedIcon fontSize="small" />
              )}
              {field.type === 'refund'&& (
                <MoneyOffIcon fontSize="small" />
              )}
              {field.type === 'cod'&& (
                <LocalAtmIcon fontSize="small" />
              )}
              {field.type === 'error'&& (
                <ErrorIcon fontSize="small" />
              )}
              <Box>
                <Typography variant='caption' sx={{display:'block'}}>
                  {field.text}
                </Typography>
                <Typography variant='caption'>
                {dayjs(field.date_created).fromNow()}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
          <Tooltip title={<span>Elimina notifica</span>}>
            <IconButton aria-label="delete notifications" onClick={() => handleDeleteNotification(field.id)}>
              <ClearIcon fontSize='small'/>
            </IconButton>
          </Tooltip>
          </Box>
         )})}
         {notifications.length === 0 && 
          <MenuItem>
              <Typography variant='caption' sx={{color:theme.palette.grey.light}}>
                Non ci sono notifiche da mostrare
              </Typography>
          </MenuItem>
         }
         {notifications.length > 0 && 
          <MenuItem sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Button variant={'text'} onClick={handleDeleteAllNotifications} sx={{color:theme.palette.red.main}}>
                  Elimina tutte
              </Button>
          </MenuItem>
         }
        </Menu>
    
  );
};
