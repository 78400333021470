import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Tooltip,
  MenuItem
} from '@mui/material';
import { Lock, NoEncryption } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { useValue } from 'context/ContextProvider';
import UserIcons from 'components/UserIcons';
import { Link, useNavigate } from 'react-router-dom';
import { NdrHorizontal } from './logo/NdrHorizontal';
import useWindowDimensions from 'services/getWindowDimensions';
import { Helmet } from 'react-helmet';
const NavBar = () => {
  const { width, height } = useWindowDimensions();
  const theme = useTheme();
  const navigate = useNavigate()
  const pages =  [{title: 'Home', path:"/" },
                  {title: 'Contatti', path:"/contacts" },
                  {title: 'Prezzi', path:"/prices" },
                  {title: 'Risorse', path:"/risorse"},
                  {title: 'Tracking', path:"/tracking"}, ];

  const {
    state: { currentUser, colors },
    dispatch,
  } = useValue();
  const url = process.env.REACT_APP_PUBLIC_URL + '/logos'
  
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const size = useWindowDimensions()
  return (
    <Box sx={{backgroundColor:{xs:'transparent', sm:theme.palette.backGround.light}, pb:1.6, pl:10}} id='navbar-main'>
       <Helmet>
            <link rel="preload" href="assets/icons/ndr-logo.ico" as="image" />
        </Helmet>
      <AppBar style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', left:'50%', transform: 'translate(-50%)',maxWidth:size.width < 600? size.width-36:1200, boxShadow: 'none', width:'100%' ,color: colors.isLight ? theme.palette.grey.main: theme.palette.primary.dark,
             }} 
              position="fixed" 
              sx={{mt:1, borderRadius:10,}}>
        <Container sx={{maxWidth:'lg'}}>
          <Toolbar disableGutters >
            
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              {width > 600 ? <MenuIcon /> : <img srcSet={`${url}/ndr-logo-256.webp 256w, ${url}/ndr-logo-50.webp 50w`} src={`${url}/ndr-logo-256.webp`} alt="NDR Logo" height={154} width={256} style={{maxWidth:50}}/>}
            </IconButton>
            <Menu
              disableScrollLock={true}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu} component={Link} to={page.path} >
                    <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } , justifyContent:'flex-start', alignItems:'center',  color: 'inherit'}}>
                <a href='/' title="Back to Home Page" >
                  <NdrHorizontal isLink={true} height={40} width={150}/>
                </a>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              
               <Button 
                component={Link}
                to={page.path}
                onClick={handleCloseNavMenu}
                key={page.title}
                sx={{ my: 2, color: 'inherit', display: 'block', display: {textDecoration: 'none'} }}>
                    {page.title}
               </Button>
            ))}
            </Box>


            {!currentUser ? (<>
              <Tooltip title={<span>Supporto</span>}>
                <IconButton aria-label="support" color="inherit" onClick={() =>navigate('/support')}>
                  <SupportAgentIcon />
                </IconButton>
              </Tooltip>
              <Button
                color="inherit"
                startIcon={<Lock />}
                onClick={() => dispatch({ type: 'OPEN_LOGIN' })}
              >
                Login
              </Button>
              </>
            ) : (
              <UserIcons />
            )}
          </Toolbar>
         
        </Container>
      </AppBar>
      <Toolbar />
      </Box>
  );
};


export default NavBar;