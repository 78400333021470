import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  Slide,
  Container,
  Tooltip
} from '@mui/material';
import { CountDownTimer } from 'common/CountDownTimer';
//import { PartnersBanner } from 'features/home/PartnersBanner';
import { useState, useEffect, useRef, lazy} from 'react';
import { useValue } from 'context/ContextProvider';
import image from 'assets/images/worldmap.svg'
import { useTheme } from '@mui/material/styles';
import { ShippingSteps } from 'features/home/ShippingSteps';
import { UpgradeAndTopUp } from 'components/UpgradeAndTopUp';
import { ServicesList } from 'features/services/ServicesList';
//import { ServicesView } from 'features/services/ServicesView';
//import { Business } from 'components/Business';
import useWindowDimensions from 'services/getWindowDimensions';
import { ShippingModule } from 'components/ShippingModule';
import { Slogan } from 'components/Slogan';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Link } from 'react-router-dom';
import { NdrHorizontal } from 'components/logo/NdrHorizontal';
import { NdrSquare } from 'components/logo/NdrSquare';
import {Helmet} from "react-helmet";
import 'react-lazy-load-image-component/src/effects/blur.css';
//import { Reviews } from 'features/home/Reviews';
const PartnersBanner = lazy(() => import('features/home/PartnersBanner').then(module => ({ default: module.PartnersBanner })));

const ServicesView = lazy(() => import('features/services/ServicesView').then(module => ({ default: module.ServicesView })));
const Business = lazy(() => import('components/Business').then(module => ({ default: module.Business })));
const Reviews = lazy(() => import('features/home/Reviews').then(module => ({ default: module.Reviews })));


export const Default = () => {

  //background image url
  const BackgroundLg = process.env.REACT_APP_PUBLIC_URL + '/images/van-night/van-night-2560.jpg'
  const BackgroundMd = process.env.REACT_APP_PUBLIC_URL + '/images/van-night/van-night-1280.jpg'
  const BackgroundSm = process.env.REACT_APP_PUBLIC_URL + '/images/van-night/van-night-853.jpg'
  const [phrase ,setPhrase] = useState('')
  const [navBarHeight, setNavBarHeight] = useState(0)
  const theme = useTheme();
  const { width, height } = useWindowDimensions();
const {
  state: { currentUser },
  dispatch,
} = useValue();
useEffect(()=>{
  const rand = getRandomInt(1,10)
},[])

const phrases = [ 
                ['La tua spedizione','a portata di click.'],
                ['Collegare mondi,',' consegnare promesse.'],
                ['Quota.','Confronta.','Spedisci.','Traccia.'],
                ['Dal piccolo pacco' , 'alla grande impresa.'],
                ['Velocità e sicurezza,',' il binomio NDR.'],
                ['Naviga nel mondo delle spedizioni',' con NDR.'],
                ]

function  getRandomInt(min, max) {
  min = Math.ceil(min); // Ensure the minimum is rounded up to the nearest integer
  max = Math.floor(max); // Ensure the maximum is rounded down to the nearest integer
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

useEffect(()=>{
setPhrase(phrases[getRandomInt(0,phrases.length)])
},[])

useEffect(() => {
  const navBar = document.getElementById('navbar-main');
  if (navBar) {
      setNavBarHeight(navBar.clientHeight);
  }
  }, []);

return(<>
<Helmet>
    <link rel="canonical" href="https://www.ndrspedizion.com" />
    <title>Spedire online in Italia o all' estero alle migliori tariffe</title>
    <meta name='description' content="Scopri il modo più semplice per spedire pacchi in Italia e all'estero. Ottieni un preventivo di spedizione istantaneo sul nostro sito e goditi un servizio affidabile, veloce e conveniente. La tua soluzione ideale per tutte le esigenze di spedizione." />
     {/* Open Graph Tags */}
    <meta property="og:title" content="Spedire online in Italia o all' estero facile alle migliori tariffe" />
    <meta property="og:description" content="Scopri il modo più semplice per spedire pacchi in Italia e all'estero. Ottieni un preventivo di spedizione istantaneo sul nostro sito e goditi un servizio affidabile, veloce e conveniente. La tua soluzione ideale per tutte le esigenze di spedizione." />
    <meta property="og:image" content="https://www.ndrspedizioni.com/logo.png" />
    <meta property="og:image:alt" content="NDR Logo" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    {/* Twitter Card Tags */}
    <meta name="twitter:card" content="https://www.ndrspedizioni.com/images/van-night/van-night-2560.jpg" />
    <meta name="twitter:title" content="Spedire online in Italia o all' estero facile alle migliori tariffe" />
    <meta name="twitter:description" content="Scopri il modo più semplice per spedire pacchi in Italia e all'estero. Ottieni un preventivo di spedizione istantaneo sul nostro sito e goditi un servizio affidabile, veloce e conveniente. La tua soluzione ideale per tutte le esigenze di spedizione." />
    <meta name="twitter:image" content="https://www.ndrspedizioni.com/logo.png" />

</Helmet>            
<Box sx={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start',
          backgroundImage: {xs: 'none', sm:`url(${BackgroundSm})`, md:`url(${BackgroundMd})`,lg:`url(${BackgroundLg})`}, 
          backgroundSize: 'cover',  
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center',  
          minHeight:`calc(100vh - ${navBarHeight}px)`,
          boxShadow: {xs:'none', sm:'inset 0 -20px 20px -15px rgba(0, 0, 0, 0.5)'}
          }}> 
  <Container sx={{ justifyContent:'center', alignItems:'center', alignContent:'center', pb:3}} >
  
    <Grid container spacing={2} sx={{mt:3,mb:3, display:'flex', justifyContent:'center'}}>
    
    <Grid item  xs={12} display={{xs:'none',sm:'block'}} >
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Box sx={{display:'flex', alignItems:'center',  justifyContent:'center', alignContent:'center'}}>
                <Box className='module' sx={{ maxWidth:400,maxHeight:200,}}>
                    <Box component={"div"} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:1}}className='module-inside'>
                        {
                            height < 900  ? <NdrHorizontal isLink={false}/> : <NdrSquare isLink={false} />
                        }
                    </Box>
                </Box>
            </Box>
        </Slide>
    </Grid>
    <Grid item xs={12} sx={{color:theme.palette.backGround.light}}>
      <Box sx={{display:'flex',alignItems:'center', justifyContent:'center',p:1}}>
          <Typography variant='h1'>
              Spedire online in tutto il mondo
          </Typography>
      </Box>
      <Box sx={{display:'flex',alignItems:'center', justifyContent:'center',p:1}}>
          <Typography variant='subtitle1' sx={{fontFamily:'roboto', fontStyle:'oblique'}}>
              Alle tariffe più vantaggiose
          </Typography>
      </Box>
    </Grid>
           
      
                
          <Grid item  xs={12} sx={{mt:{xs:0,sm:3}}}>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit style={{ transitionDelay:'500ms'}}>
                <Paper sx={{ borderRadius:8 }} elevation={15}>
                    <ShippingModule/>
                </Paper>
            </Slide>
          </Grid>
          <Grid item xs={12}  display={{xs:'none', sm:'block'}}>
              <Box sx={{justifyContent: 'center',alignContent: 'center',alignItems: 'center', display: 'flex',opacity: 0, animation: 'fadeIn ease-out 500ms forwards', 
          '@keyframes fadeIn': {'0%': { opacity: 0,},'100%': {opacity: 1,},},animationDelay: '800ms',}}>
                   <CountDownTimer/>
              </Box>
             
          </Grid>
          <Grid item xs={12}  display={{xs:'none', sm:'none', md:'none',lg:'block'}} >
              <Box sx={{justifyContent:'center', alignContent:'center', alignItems:'center', display:'flex'}}>
                  <Slogan parts={phrase}/>
              </Box>
          </Grid>
          
       
          {height > 800 && currentUser === null &&
          <Grid item xs={12} display={{xs:'none', sm:'flex', md:'flex',lg:'flex'}}>
            <Box sx={{position: 'absolute',top:`calc(100vh - ${navBarHeight}px)`, left:0, right:0,ml:3,display: 'flex',alignItems: 'center',justifyContent: 'center',padding: '16px',gap:1,}}>
                <VerifiedIcon sx={{color:theme.palette.backGround.main}}/>
                <Typography variant='button' sx={{color:theme.palette.backGround.main}}>
                  Non possiedi ancora un account?
                </Typography>
                <Tooltip title={<span>E' gratis e non richiede carta di credito</span>}>
                    <Button variant='text' component={Link} to={'/register'} color='primary'>
                      Registrati subito!
                    </Button>
                </Tooltip>
            </Box>
          </Grid>
          }
      </Grid>
      
  </Container>
  </Box>
  
  <ShippingSteps />
  <UpgradeAndTopUp />
  <Box sx={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start',
          backgroundImage: `url(${image})`, 
          backgroundSize: 'cover',  
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center',  
          minHeight:'100vh',
          boxShadow: {xs:'none', sm:'inset 0 -20px 20px -15px rgba(0, 0, 0, 0.5)'}
          }}> 
      <Container  sx={{display:'flex', alignItems:'center', justifyContent:'center',minHeight:'100vh', p:3}}>
          
          <Grid container spacing={2} sx={{display:'flex', alignItems:'stretch'}}>
          {ServicesList && ServicesList.map((item, index) => { return (
              item.enabled && (
              <Grid key={index} item xs={12} sm={6} md={4} sx={{display:'flex'}}>
                  <ServicesView  service={item}/>
              </Grid>
              )
          )})}
          </Grid>
      </Container>
  </Box>

  <PartnersBanner/>
 
    <Box sx={{backgroundColor:theme.palette.backGround.light}}>
      <Container sx={{minHeight:'50vh', display:'flex', alignItems:'center', justifyContent:'center', p:3}}>
          <Business />
      </Container>
    </Box>
    <Box sx={{backgroundColor:theme.palette.backGround.light}}>
        <Reviews/>
    </Box>  
  </>
)
}