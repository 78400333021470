import { Lock } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useValue } from 'context/ContextProvider';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';
import useCheckToken from 'features/auth/useCheckToken';
export const AccessMessage = ({message}) => {
    useCheckToken()
    const {
        state: { currentUser, authLoading},
        dispatch,
      } = useValue();
  const theme = useTheme();
  return (
    <Container sx={{display:'flex', alignItems:'center', justifyContent:'center',minHeight:600}}>
    {!authLoading  ?
    <Grid container spacing={2}>
        <Grid item xs={12}  sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Box className={'space-invader'}/>
        </Grid>
        <Grid item xs={12}>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',gap:1, mb:5}}>
                <Typography variant='h3' sx={{color:theme.palette.grey.main}}>
                   Accesso Proibito
                </Typography>
            </Box>
        </Grid>
        <Grid item xs={12} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Typography variant='subtitle1' paragraph sx={{color: theme.palette.grey.main}}>
                {message ? message : 'Effettua il login per visualizzare la pagina'}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Button variant={'outlined'} size={'large'}
                  sx={{p:2,borderRadius:5}}
                  fullWidth
                  component={Link} to={'/'}
                  startIcon={<HomeIcon fontSize='large'/>}
            >
                Torna alla Home
            </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Button variant={'contained'} 
              sx={{p:2,borderRadius:5}}
              fullWidth
              size={'large'}
              startIcon={<PersonIcon fontSize='large'/>}
              color={'secondary'} onClick={()=>dispatch({type: 'OPEN_LOGIN'})}>
                Accedi
            </Button>
        </Grid>   
        </Grid>
         : <CircularProgress aria-label="Loading..."/>}
    </Container>
    
  );
};