import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useRef,
  } from 'react';
  import reducer from './reducer';
  
  const initialState = {
    currentUser: null,
    openLogin: false,
    openAddressBook: false,
    openShippingModule: false,
    openCookieBanner: true,
    openAdvancedCookieBanner:false,
    openShippingBusinessModule: false,
    openAddTracking: false,
    loading: false,
    authLoading: true,
    alert: { open: false, severity: 'info', message: '' },
    dialog: {open: false, close: false, id: 0, title: '', message: '', onSumbit: undefined },
    profile: { open: false, file: null, photoURL: '' },
    //cookieSettings --> essentials: true, functional:false, experience: false, stats:false, marketing:false <-- DEFAULT VALUES
    cookieSettings: {essentials: true, functional:null, experience: null, stats:true, marketing:null},
    //createEditPlan
    createEditPlan: {name: '', description: '', code:'', isPackage: false, isPallet: false, isEnabled: false, isImport: false, isOnline: false, planType: 'selling', planCategory: 'express',
    maxLength:'',maxHeight:'',maxWidth:'',maxWeight:'', maxPerimeter:'', maxField: '',minField:'', deliveryTime: '', planDiv: '', 
    manualHandlingLength:null,  manualHandlingWidth:null,  manualHandlingWeight:null,  manualHandlingPrice:null,
    handlingPackages:null, handlingPrice:null,
    nonStackablePrice:null,additionalHandlingPrice:null,dangerousGoodsPrice:null,
    priceCeilings: [{fieldFrom:'', fieldTo: '', increment: '', price: ''}],
    planRows: [{fieldFrom:'', fieldTo:'', price:''}],
    insurance: {isInsurance:false, insurancePercentage: '', insuranceMinAmount:''},
    cod: {isCod: false,codPercentage: '', codMinAmount: ''},
    pickupResidential: {isPickupResidential: false, pickupResidentialAmount: ''},
    deliveryResidential: {isDeliveryResidential: false, deliveryResidentialAmount: ''},
    planUsersCouriers: [], planRule: {id: null, name: null, description:null},
    surchargePrice : null, surchargeFunction: 'Nessuno', validFunction:'Nessuno'},
    //createEditRule
    createEditRule : {name: '', description: '', stateValidCode: 'IT', stateValidLabel: 'Italia', capIsValid:[{capFrom: '', capTo:''}], isCapValid: false, createOppositeRule: false, isDefault:false, defaultRuleName: null},
    //newShipping
    newShipping: {sender: {name: '', surname: '', phoneNumber: '', businessName: '' ,email:'', priority_courier_id: null}, recipient: {name: '', surname: '', phoneNumber: '', businessName: '',email:'', priority_courier_id: null}, customer: null, 
    senderAddress: {state: '', cap: '', province: '', streetNumber: '', city: '', address:'', customAddress: '', lat:null, lng:null, isResidential: false}, 
    recipientAddress:{state: '', cap: '', province: '', streetNumber: '', city: '', address:'', customAddress: '', lat:null, lng:null, isResidential: false}, 
    shippingRows: [{length: '', height:'', width:'', weight:'' }],
    isCod: false, codAmount: 0, isInsurance: false, insuranceAmount: 0, isAllowed:false, pickUpDate: null, description:'', value:0,
    shippingQuotes: [], selectedQuote: null, courierQuotes: [], selectedCourierQuote: null, message: '', customer: null, customerRole: null,packageType:'box',
    serviceQuote: {insuranceAmount : 0, codAmount : 0, pickupResidentialAmount : 0, deliveryResidentialAmount:0}, paymentType:'', privacy:false, terms:false, marketing:false, subscription:false, discountId:null, saveSender:false, saveRecipient:false},
    //courierDetails
    courierDetails: {name: '', description: '', trackingUrl: '', url: '', surchargeFunction:'', fuel : ''}, 
    //userProfile
    userProfile: {name: null, surname: null, email: null, phoneNumber: null, taxIdCode : null, address: {state: null, cap: null, city:null, province: null, address:null, streetNumber: null, isResidential:false}, 
    businessName:null,piva: null, iban: null, sdi:null, businessAddress: {state: 'it', cap: null, city:null, province: null, address:null, streetNumber: null},
    privacy: false, marketing:false, conditions:false, subscription:false,darkMode:false,balance:0,updateProfile: false, updateAddress:false,
    oldPassword: '', newPassword:'', passwordConfirm:''},
    //request
    request: {shippingId: null, customerId: null, type: '', title:'', body:''},
    //invoice
    invoice: {shippingList: [], surchargeList: [],customer: null, amount: 0, invoiceNumber: null, numeration:null, dateCreated: null,expiryDate: '0', payment_method_id:null},
    //discount
    discount: {code:'',type:'', amount:'',minAmount:'',isPercentage:false,daysValid:''},
    //filters
    shippingFilter: {paymentStatus: null, type: '', days: null, startDate:null, endDate:null,shippingStatus: '', search: '', orderByColumn: 'date', orderByType:'desc', page:0, limit:20, customer:null,firstRender:true},
    invoiceFilter: {state: null, customer:null,type: '', days: null, startDate:null, endDate:null, date:null,search: '', orderByColumn: 'date', orderByType:'desc',page:0, limit:20, checkAll:null, uncheckAll:null,firstRender:true},
    discountFilter: {state: null, type: '', days: null,startDate:null, endDate:null, search: '', orderByColumn: 'date', orderByType:'desc',page:0, limit:20,firstRender:true},
    ticketFilter: {state: '', type: '', search: '',orderByColumn: 'date', orderByType:'desc',page:0, limit:20,customer:null,firstRender:true},
    topUpFilter: {days: null},
    courierFilter: {daysPanel: null, search: '', orderByColumn: 'date', orderByType:'desc'},
    ruleFilter: {search: '', orderByColumn: 'date', orderByType:'desc',page:0, capRowsPage:0, limit:20},
    userFilter: {days: null, startDate:null, endDate:null,daysPanel: null, role: '',isEnabled:null,hasInvoice:null,search:'',orderByColumn: 'date', orderByType:'desc',page:0, limit:20, firstRender:true},
    planFilter: {days: null,startDate:null, endDate:null,daysPanel: null, type: '', isEnabled:null,isOnline:null, hasRule:null, search:'', orderByColumn: 'date', orderByType:'desc',page:0, limit:20, planRowsPage:0, isEdit:false,customer:null,firstRender:true},
    dashboardFilter:{days:null,startDate:null, endDate:null,firstRender:true},
    addressBookFilter: {letter:null, search : '', orderByColumn: 'name', orderByType:'desc',firstRender:true},
    affiliateFilter: {startDate:null, endDate:null,paymentStatus: null, search: null, orderByColumn: 'date', orderByType:'desc', page:0, limit:20, customer:null, affiliate:null,firstRender:true},
    //shippingError
    shippingError: {senderName: false, senderBusinessName:false, senderPhone:false, senderState: false, senderCap:false, senderProvince: false, senderCity: false, senderAddress: false, senderStreetNumber:false, senderEmail: false,
    recipientState: false, recipientCap:false, recipientProvince:false,recipientCity:false, recipientAddress: false,recipientStreetNumber:false, recipientName:false, recipientBusinessName:false,recipientPhone: false, recipientEmail: false,
    cod:false, insurance: false, length: false, height:false, width: false, weight: false,
    description: false, value:false},
    //planError
    planError: {name: false, description: false,maxLength:false,maxHeight:false,maxWidth:false,maxWeight:false, maxPerimeter:false, maxField: false, minField:false, surchargePrice:false, deliveryTime: false, planDiv: false, pickupResidential:false, deliveryResidential:false,
    codPercentage:false, codMinAmount:false, insurancePercentage: false, insuranceMinAmount:false, rule:false, usersOrCouriers:false, errFieldFrom: false, errFieldTo:false, errPrice:false,  manualHandlingLength:false,  manualHandlingWidth:false,  manualHandlingWeight:false,  manualHandlingPrice:false,
    handlingPackages:false, handlingPrice:false,
    nonStackablePrice:false,additionalHandlingPrice:false,dangerousGoodsPrice:false,},
    //userError
    userError: {name: false, surname: false, email: false, phoneNumber: false, taxIdCode:false, address: {state:false, cap:false, city:false, cap:false, province:false, address:false, streetNumber:false} ,oldPassword: false, newPassword:false, passwordConfirm:false,
    businessName:false, piva:false, iban:false, sdi:false, businessAddress: {state:false, cap:false, city:false, cap:false, province:false, address:false, streetNumber:false}},
    //courierError
    courierError: {name: false, fuel: false, url:false},
    //discountError
    discountError: {code: false, type:false, amount:false, daysValid:false},
    //other stuff
    currentYear: null,
    enableApi: false,
    exportedShippings:null,
    topUp: null,
    courier: null,
    user: null,
    plan: null,
    rule: null,
    selectedPlanRow: null,
    selectedCapRow:null,
    selectedPriceCeiling:null,
    shipping: null,
    contactForm: {name: '', email:'', message:''},
    updatedPlan: null,
    colors: {bodyColor: '#25CA8C', backgroundColor: '#cccccc', isLight: false, isDarkMode:false},
    prevPage: null,
  };
  
  const Context = createContext(initialState);
  
  export const useValue = () => {
    return useContext(Context);
  };
  
  const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const mapRef = useRef();
    const containerRef = useRef();
    useEffect(() => {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser) {
        dispatch({ type: 'UPDATE_CURRENT_USER', payload: currentUser });
      }
    }, []);

    useEffect(()=>{
      const currentYear = Date.now()
      dispatch({ type: 'UPDATE_CURRENT_YEAR', payload: currentYear });
    },[])
  
   /*  useEffect(() => {
      if (state.currentUser) {
        const room = JSON.parse(localStorage.getItem(state.currentUser.id));
        if (room) {
          dispatch({ type: 'UPDATE_LOCATION', payload: room.location });
          dispatch({ type: 'UPDATE_DETAILS', payload: room.details });
          dispatch({ type: 'UPDATE_IMAGES', payload: room.images });
          dispatch({ type: 'UPDATE_UPDATED_ROOM', payload: room.updatedRoom });
          dispatch({
            type: 'UPDATE_DELETED_IMAGES',
            payload: room.deletedImages,
          });
          dispatch({ type: 'UPDATE_ADDED_IMAGES', payload: room.addedImages });
        }
      }
    }, [state.currentUser]); */
    return (
      <Context.Provider value={{ state, dispatch, mapRef, containerRef }}>
        {children}
      </Context.Provider>
    );
  };
  
  export default ContextProvider;
  