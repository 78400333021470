
import axios from 'axios'
import { useEffect } from 'react';
import {AuthHeader} from 'services/AuthHeader';

export const UserApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

export const getAllUsers = async (startDate, endDate, role, isEnabled,hasInvoice,orderByColumn, orderByType, limit, page,search) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get("/users/",  {params: {startDate: startDate, endDate: endDate, role: role, isEnabled:isEnabled, hasInvoice:hasInvoice,orderByColumn:orderByColumn, orderByType:orderByType, limit:limit,page:page,search:search}})
    return response.data
}


export const getInvoiceUserDetails = async (search, type) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get("/users/invoice-user-details/",  {params: {search: search, type: type}})
    return response.data
}

export const getUserById = async (id) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get(`/users/${id}`, id)
    return response.data
}

export const getUserDarkMode = async(id)=> { 
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get(`/users/dark-mode/${id}`)
    return response.data
}

export const getEnableCourierApi = async()=> { 
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get(`/users/enable-api/`)
    return response.data
}

export const getEnableDailyPickup = async(id)=> { 
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get(`/users/daily-pickup/${id}`)
    return response.data
}

export const getUserCalendar = async(id)=> { 
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get(`/users/calendar/${id}`)
    return response.data
}

export const getUserStats = async(id, startDate, endDate)=> { 
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get(`/users/stats/${id}`,{params: { startDate: startDate, endDate: endDate}})
    return response.data
}

export const createUser = async (user) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.post("/users/",user)
}
export const updateUserDetails = async (user) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.put(`/users/details/${user.id}`,user)
}

export const updatePassword = async (user) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.put(`/users/password/${user.id}`,user)
}

export const updateUserSettings = async (user) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.put(`/users/settings/${user.id}`,user)
}

export const updateDailyPickupTime = async (user) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.put(`/users/daily-pickup-time/${user.id}`,user)
}

export const updateUserDarkMode = async (user) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.put(`/users/dark-mode/${user.id}`,user)
}

export const enableCourierApi = async () => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.put(`/users/enable-api/`)
}

export const enableDailyPickup = async (id) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.put(`/users/daily-pickup/${id}`)
}

export const enableUser = async (user) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.put(`/users/enable/${user.id}`,user)
}

export const updateUserRole = async (user) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.put(`/users/role/${user.id}`,user)
}

export const deleteUser = async ({ id }) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    return await UserApi.delete(`/users/${id}`, id)
}

export const getBusinessUsers = async () => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get("/users/business/")
    return response.data
}

export const getPaymentMethods = async (user_id) => {
    UserApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await UserApi.get("/users/payment-methods/",  {params: {user_id: user_id}})
    return response.data
}