import React from 'react';
import LogoXl from 'assets/logo/ndr-square/ndr-square-1200.png'
import LogoLg from 'assets/logo/ndr-square/ndr-square-800.png'
import LogoMd from 'assets/logo/ndr-square/ndr-square-400.png'
import LogoSm from 'assets/logo/ndr-square/ndr-square-200.png'
import LogoXs from 'assets/logo/ndr-square/ndr-square-100.png'
export const NdrSquare = ({height, width, isLink}) => {

  return (
    <img
        alt={'Spedire online in tutto il mondo'}
        srcSet={`${LogoXs} 100w, ${LogoSm} 200w, ${LogoMd} 400w, ${LogoLg} 800w, ${LogoXl} 1200w,`}
        src = {LogoXl}
        width={width ? width:1200}
        height={height ? height : 622}
        className={isLink && 'image-hover-white'}
    />
  );
};
