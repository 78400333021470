import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useValue } from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import { updatePassword } from 'features/admin/users/hooks/UserApi';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  InputAdornment,
  TextField,
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  CircularProgress
} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query'
import PasswordIcon from '@mui/icons-material/Password';
import { validPassword } from 'services/Regex';
export const PasswordChange = ({isNew}) => {
    const queryClient = useQueryClient()
    const oldPasswordRef= useRef();
    const newPasswordRef= useRef();
    const passwordConfirmRef= useRef();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const theme = useTheme();
    const {
      state: {  userProfile, userError, currentUser },
      dispatch,
    } = useValue();

    const handleFieldsChange = (e, index) => {
      e.preventDefault();
      handleValidation();
      dispatch({type: 'UPDATE_USER_PROFILE', payload: {...userProfile, [e.target.name]: e.target.value}})
    }
    
    const handleMouseDown = (e) => {
      e.preventDefault();
    };

    const handleValidation = () => {
      let oldErr = false
      let newErr = false
      let confirmErr = false
      if (!isNew){
        oldPasswordRef.current.value === '' ? oldErr = true : oldErr = false
      }
      if (!validPassword.test(newPasswordRef.current.value)){
          newErr = true;
      }else {
          newErr = false;
      }
      if (passwordConfirmRef.current.value === ''){
        confirmErr = true
      } else{
        if (passwordConfirmRef.current.value !== newPasswordRef.current.value){
          confirmErr = true
        } else {
          confirmErr = false
        }
      }
      dispatch({type: 'UPDATE_USER_ERROR' , payload: {...userError, oldPassword: oldErr, newPassword: newErr, passwordConfirm: confirmErr}})
      if (oldErr || newErr || confirmErr){
          return false
      } else {
          return true
      }
  }

  const saveUserDetails = () => {
    if (handleValidation()) {
      mutate({id:currentUser.id ,oldPassword: userProfile.oldPassword, newPassword: userProfile.newPassword })
    }
  }

  useEffect(()=>{
    if(isNew) dispatch({type : 'RESET_USER_PROFILE'})
  },[])
  
  const {isLoading, isError, mutate, error} = useMutation(updatePassword, {  
    onError: (data)=> {
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: <div>{data.response.data.detail}</div>
          },}) 
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['user', currentUser?.id])
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'success',
            message: <>{'Password aggiornata correttamente, si consiglia di effettuare il login nuovamente.'}</>
          },}) 
    },
  });

  return (
    <Grid container spacing={2} sx={{display:'flex', alignItems:'center'}}>
        <Grid item xs={12} sm={12} md={6} order={{xs:2, sm:2, md:1}}>
            <Grid container spacing={2}>
              {!isNew && (
                <Grid item xs={12}>
                    <TextField
                        InputProps={{ sx: { borderRadius: 5 } }}
                        required
                        name="oldPassword"
                        label="Vecchia Password"
                        id="oldPassword"
                        fullWidth
                        value={userProfile.oldPassword || ''}
                        variant="outlined"
                        type="password"
                        inputRef={oldPasswordRef}
                        onChange={(e) => handleFieldsChange(e)}
                        error={userError.oldPassword}
              
                    />
                </Grid>
                )}
                <Grid item xs={12}>
                    <TextField
                         InputProps={{
                          sx: { borderRadius: 5 },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="show password"onClick={()=>setShowPassword(prev=>!prev)} onMouseDown={handleMouseDown}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                        name="newPassword"
                        label="Nuova Password"
                        id="newPassword"
                        fullWidth
                        value={userProfile.newPassword || ''}
                        inputRef={newPasswordRef}
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => handleFieldsChange(e)}
                        error={userError.newPassword}
              
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                         InputProps={{
                          sx: { borderRadius: 5 },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="show password" onClick={()=>setShowConfirmPassword(prev=>!prev)} onMouseDown={handleMouseDown}>
                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                        name="passwordConfirm"
                        label="Conferma nuova Password"
                        id="confirmNewPassword"
                        value={userProfile.passwordConfirm}
                        inputRef={passwordConfirmRef}
                        fullWidth
                        variant="outlined"
                        type={showConfirmPassword ? 'text' : 'password'}
                        onChange={(e) => handleFieldsChange(e)}
                        error={userError.passwordConfirm}
              
                    />
                </Grid>
                {!isNew && (
                <Grid item xs={12}>
                  <Button variant={'contained'} size='large'  sx={{p:2,borderRadius:5}} fullWidth onClick={saveUserDetails} color={'secondary'} endIcon={isLoading && <CircularProgress aria-label="Loading..." size={20} sx={{color:'white'}}/>}>{'Aggiorna'}</Button>
                </Grid>
                )}
            </Grid>
           
        </Grid>
        <Grid item xs={12} sm={12} md={6} order={{xs:1, sm:1, md:2}} sx={{color:theme.palette.mode === 'light' ? theme.palette.grey.light : theme.palette.backGround.main}}>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <PasswordIcon fontSize='large' />
          </Box>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Typography variant='h5' sx={{mb:1}}>
                {isNew ? 'Password' : 'Cambia la Password'}
            </Typography>
          </Box>
       </Grid>
    </Grid>
   
  )
}
    