import React, {useState, useEffect} from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
export const Slogan = ({ parts, initialDelay = 1000, delayBetweenParts = 500 }) => {
    const [startAnimation, setStartAnimation] = useState(false);
    const theme = useTheme();
    useEffect(() => {
      const timer = setTimeout(() => {
        setStartAnimation(true);
      }, initialDelay);
      
      return () => clearTimeout(timer);
    }, [initialDelay]);
  
    return (
      <Box sx={{ display: 'flex', justifyContent:'center', alignItems: 'center',gap:1,color:theme.palette.backGround.main}}>
        {parts && parts.map((part, index) => (
          <Typography
            variant={'h4'}
            key={index}
            component="span"
            sx={{
              opacity: 0,
              animation: startAnimation ? `fadeIn 1s ${index * delayBetweenParts}ms forwards` : 'none',
              '@keyframes fadeIn': {
                '0%': { opacity: 0 },
                '100%': { opacity: 1 },
              },
            }}
          >
            {part}
          </Typography>
        ))}
      </Box>
    );
  };

