const createEditPlanIntialValue = {name: '', description: '', code:'', isPackage: false, isPallet: false, isEnabled: false, isImport: false, isOnline: false, planType: 'selling', planCategory: 'express',
maxLength:'',maxHeight:'',maxWidth:'',maxWeight:'', maxPerimeter:'', maxField: '',minField:'', deliveryTime: '', planDiv: '', 
manualHandlingLength:'',  manualHandlingWidth:'',  manualHandlingWeight:'',  manualHandlingPrice:'',
handlingPackages:'', handlingPrice:'',
nonStackablePrice:'',additionalHandlingPrice:'',dangerousGoodsPrice:'',
priceCeilings: [{fieldFrom:'', fieldTo: '', increment: '', price: ''}],
planRows: [{fieldFrom:'', fieldTo:'', price:''}],
insurance: {isInsurance:false, insurancePercentage: '', insuranceMinAmount:''},
cod: {isCod: false,codPercentage: '', codMinAmount: ''},
pickupResidential: {isPickupResidential: false, pickupResidentialAmount: ''},
deliveryResidential: {isDeliveryResidential: false, deliveryResidentialAmount: ''},
planUsersCouriers: [], planRule: {id: null, name: null, description:null},
surchargePrice : null, surchargeFunction: 'Nessuno', validFunction:'Nessuno'}
const createEditRuleInitialValue = {name: '', description: '', stateValidCode: 'IT', stateValidLabel: 'Italia', capIsValid:[{capFrom: '', capTo:''}], isCapValid: false, createOppositeRule: false, isDefault:false, defaultRuleName: null}
const newShippingInitialValue = {sender: {name: '', surname: '', phoneNumber: '', businessName: '' ,email:'', priority_courier_id: null}, recipient: {name: '', surname: '', phoneNumber: '', businessName: '',email:'', priority_courier_id: null}, customer: null, 
senderAddress: {state: '', cap: '', province: '', streetNumber: '', city: '', address:'', customAddress: '', lat:null, lng:null, isResidential: false}, 
recipientAddress:{state: '', cap: '', province: '', streetNumber: '', city: '', address:'', customAddress: '', lat:null, lng:null, isResidential: false}, 
shippingRows: [{length: '', height:'', width:'', weight:'' }],
isCod: false, codAmount: 0, isInsurance: false, insuranceAmount: 0, isAllowed:false, pickUpDate: null, description:'', value:0,
shippingQuotes: [], selectedQuote: null, courierQuotes: [], selectedCourierQuote: null, message: '', customer: null, customerRole: null,packageType:'box',
serviceQuote: {insuranceAmount : 0, codAmount : 0, pickupResidentialAmount : 0, deliveryResidentialAmount:0}, paymentType:'', privacy:false, terms:false, marketing:false, subscription:false, discountId:null, saveSender:false, saveRecipient:false}
const shippingErrorInitialValue =  {senderName: false, senderBusinessName:false, senderPhone:false, senderState: false, senderCap:false, senderProvince: false, senderCity: false, senderAddress: false, senderStreetNumber:false, senderEmail: false,
  recipientState: false, recipientCap:false, recipientProvince:false,recipientCity:false, recipientAddress: false,recipientStreetNumber:false, recipientName:false, recipientBusinessName:false,recipientPhone: false, recipientEmail: false,
  cod:false, insurance: false, length: false, height:false, width: false, weight: false,
  description: false, value:false}
const userErrorInitialValue = {name: false, surname: false, email: false, phoneNumber: false, taxIdCode:false, address: {state:false, cap:false, city:false, cap:false, province:false, address:false, streetNumber:false},
businessName:false, piva:false, iban:false, sdi:false, businessAddress: {state:false, cap:false, city:false, cap:false, province:false, address:false, streetNumber:false}}
const userProfileInitialValue = {name: null, surname: null, email: null, phoneNumber: null, taxIdCode : null, address: {state: null, cap: '', city:'', province: '', address:'', streetNumber: '', isResidential:false}, 
businessName:null,piva: null, iban: null, sdi:null, businessAddress: {state: null, cap: null, city:null, province: null, address:null, streetNumber: null},
privacy: false, marketing:false, conditions:false, subscription:false, darkMode:false,balance:0,updateProfile: false, updateAddress:false,
oldPassword: '', newPassword:'', passwordConfirm:''}
const planErrorInitialState = {name: false, description: false,maxLength:false,maxHeight:false,maxWidth:false,maxWeight:false, maxPerimeter:false, maxField: false, minField:false, surchargePrice:false, deliveryTime: false, planDiv: false, pickupResidential:false, deliveryResidential:false,
  codPercentage:false, codMinAmount:false, insurancePercentage: false, insuranceMinAmount:false, rule:false, usersOrCouriers:false, errFieldFrom: false, errFieldTo:false, errPrice:false,  manualHandlingLength:false,  manualHandlingWidth:false,  manualHandlingWeight:false,  manualHandlingPrice:false,
  handlingPackages:false, handlingPrice:false,
  nonStackablePrice:false,additionalHandlingPrice:false,dangerousGoodsPrice:false,}
const requestInitalValue = {shippingId: null, customerId: null, type: '', title:'', body:''}
const courierDetailsInitialState = {name: '', description: '', trackingUrl: '', url: '', fuel : ''}
const invoiceInitialState = {shippingList: [], surchargeList: [],customer: null, amount: 0, invoiceNumber: null, numeration:null, expiryDate: '0', payment_method_id:null}
const discountInitialState =  {code:'',type:'', amount:'',minAmount:'',isPercentage:false,daysValid:''}
const discountErrorInitialState = {code: false, type:false, amount:false, daysValid:false}
const contactFormInitialValue = {name:'',email:'',message:''}

const reducer = (state, action) => {
    switch (action.type) {
      case 'OPEN_LOGIN':
        return { ...state, openLogin: true };
      case 'CLOSE_LOGIN':
        return { ...state, openLogin: false };
      case 'OPEN_ADDRESS_BOOK':
          return { ...state, openAddressBook: true };
      case 'CLOSE_ADDRESS_BOOK':
          return { ...state, openAddressBook: false };
      case 'OPEN_SHIPPING_MODULE':
          return { ...state, openShippingModule: true };
      case 'OPEN_COOKIE_BANNER':
          return { ...state, openCookieBanner: true };
      case 'CLOSE_COOKIE_BANNER':
          return { ...state, openCookieBanner: false };
      case 'OPEN_ADVANCED_COOKIE_BANNER':
            return { ...state, openAdvancedCookieBanner: true };
      case 'CLOSE_ADVANCED_COOKIE_BANNER':
            return { ...state, openAdvancedCookieBanner: false };
      case 'OPEN_SHIPPING_BUSINESS_MODULE':
            return { ...state, openShippingBusinessModule: true };
      case 'CLOSE_SHIPPING_BUSINESS_MODULE':
          return { ...state, openShippingBusinessModule: false };
      case 'CLOSE_SHIPPING_MODULE':
            return { ...state, openShippingModule: false };
      case 'OPEN_ADD_TRACKING':
            return { ...state, openAddTracking: true };
      case 'CLOSE_ADD_TRACKING':
            return { ...state, openAddTracking: false };
      case 'START_LOADING':
        return { ...state, loading: true };
      case 'END_LOADING':
        return { ...state, loading: false };
      case 'START_AUTH_LOADING':
        return { ...state, authLoading: true };
      case 'END_AUTH_LOADING':
          return { ...state, authLoading: false };
      case 'UPDATE_ALERT':
        return { ...state, alert: action.payload };
      case 'UPDATE_PROFILE':
        return { ...state, profile: action.payload };
      case 'UPDATE_CURRENT_USER':
        localStorage.setItem('currentUser', JSON.stringify(action.payload));
        return { ...state, currentUser: action.payload};
      case 'UPDATE_COURIER':
        return { ...state, courier: action.payload };
      case 'UPDATE_USER':
        return { ...state, user: action.payload };
      case 'UPDATE_CURRENT_YEAR':
        return { ...state, currentYear: action.payload };
      case 'UPDATE_PLAN':
        return { ...state, plan: action.payload };
      case 'UPDATE_TOPUP':
          return { ...state, topUp: action.payload };
      case 'UPDATE_SHIPPING':
          return { ...state, shipping: action.payload };
      case 'UPDATE_RULE':
        return { ...state, rule: action.payload };
      case 'EXPORT_SHIPPINGS':
          return { ...state, exportedShippings: action.payload };
      case 'UPDATE_PLAN_ROW':
        return { ...state, selectedPlanRow: action.payload };
      case 'UPDATE_CAP_ROW':
          return { ...state, selectedCapRow: action.payload };
      case 'UPDATE_PRICE_CEILING':
        return { ...state, selectedPriceCeiling: action.payload };
      case 'UPDATE_UPDATED_PLAN':
      return { ...state, updatedRoom: action.payload };
      case 'RESET_CREATE_EDIT_PLAN':
          return { ...state, createEditPlan:  createEditPlanIntialValue };
      case 'RESET_SHIPPING_ERROR':
          return { ...state, shippingError:  shippingErrorInitialValue };
      case 'RESET_CONTACT_FORM':
          return { ...state, contactForm:  contactFormInitialValue };
      case 'RESET_PLAN_ERROR':
          return { ...state, planError:  planErrorInitialState };
      case 'RESET_COURIER_DETAILS':
          return { ...state, courierDetails:  courierDetailsInitialState };
      case 'RESET_REQUEST':
            return { ...state, request:  requestInitalValue };
      case 'RESET_NEW_INVOICE':
            return { ...state, invoice:  invoiceInitialState };
      case 'RESET_DISCOUNT':
            return { ...state, discount:  discountInitialState };
      case 'RESET_DISCOUNT_ERROR':
            return { ...state, discountError:  discountErrorInitialState };
      case 'CREATE_EDIT_PLAN':
        return { ...state, createEditPlan: { ...state.createEditPlan, ...action.payload } };
      case 'CREATE_INVOICE':
          return { ...state, invoice: { ...state.invoice, ...action.payload } };
      case 'CONTACT_FORM':
          return { ...state, contactForm: { ...state.contactForm, ...action.payload } };
      case 'CREATE_NEW_SHIPPING':
        return { ...state, newShipping: { ...state.newShipping, ...action.payload } };
      case 'RESET_NEW_SHIPPING':
        return { ...state, newShipping: newShippingInitialValue };
      case 'CREATE_EDIT_RULE':
        return { ...state, createEditRule: { ...state.createEditRule, ...action.payload } };
      case 'RESET_CREATE_EDIT_RULE':
        return { ...state, createEditRule:  createEditRuleInitialValue };
      case 'UPDATE_NEW_PLAN_SECTION':
        return { ...state, newPlanSection: action.payload };
      case 'UPDATE_SHIPPING_ERROR':
        return { ...state, shippingError: { ...state.shippingError, ...action.payload } };
      case 'UPDATE_DISCOUNT_ERROR':
        return { ...state, discountError: { ...state.discountError, ...action.payload } };
      case 'UPDATE_PLAN_ERROR':
          return { ...state, planError: { ...state.planError, ...action.payload } };
      //DASHBOARD
      case 'UPDATE_DASHBOARD_FILTER':
          return { ...state, dashboardFilter: { ...state.dashboardFilter, ...action.payload } };
      case 'UPDATE_SHIPPING_FILTER':
        return { ...state, shippingFilter: { ...state.shippingFilter, ...action.payload } };
      case 'UPDATE_INVOICE_FILTER':
        return { ...state, invoiceFilter: { ...state.invoiceFilter, ...action.payload } };
      case 'UPDATE_DISCOUNT_FILTER':
          return { ...state, discountFilter: { ...state.discountFilter, ...action.payload } };
      case 'UPDATE_ADDRESS_BOOK_FILTER':
        return { ...state, addressBookFilter: { ...state.addressBookFilter, ...action.payload } };
      case 'UPDATE_TICKET_FILTER':
        return { ...state, ticketFilter: { ...state.ticketFilter, ...action.payload } };
      case 'UPDATE_USER_FILTER':
          return { ...state, userFilter: { ...state.userFilter, ...action.payload } };
      case 'UPDATE_COURIER_FILTER':
          return { ...state, courierFilter: { ...state.courierFilter, ...action.payload } };
      case 'UPDATE_RULE_FILTER':
          return { ...state, ruleFilter: { ...state.ruleFilter, ...action.payload } };
      case 'UPDATE_PLAN_FILTER':
          return { ...state, planFilter: { ...state.planFilter, ...action.payload } };
      case 'UPDATE_TOPUP_FILTER':
        return { ...state, topUpFilter: { ...state.topUpFilter, ...action.payload } };
      case 'UPDATE_AFFILIATE_FILTER':
        return { ...state, affiliateFilter: { ...state.affiliateFilter, ...action.payload } };
      case 'UPDATE_COOKIE_SETTINGS':
          return { ...state, cookieSettings: { ...state.cookieSettings, ...action.payload } };
      case 'UPDATE_REQUEST':
        return { ...state, request: { ...state.request, ...action.payload } };
      case 'UPDATE_USER_PROFILE':
        return { ...state, userProfile: { ...state.userProfile, ...action.payload } };
      case 'UPDATE_DISCOUNT':
        return { ...state, discount: { ...state.discount, ...action.payload } };
      case 'UPDATE_COURIER_DETAILS':
          return { ...state, courierDetails: { ...state.courierDetails, ...action.payload } };
      case 'RESET_USER_ERROR':
          return { ...state, userError:  userErrorInitialValue };
      case 'RESET_USER_PROFILE':
          return { ...state, userProfile:  userProfileInitialValue };
      case 'UPDATE_USER_ERROR':
          return { ...state, userError: { ...state.userError, ...action.payload } };
      case 'UPDATE_PLAN_ERROR':
          return { ...state, planError: { ...state.planError, ...action.payload } };
      case 'UPDATE_COURIER_ERROR':
          return { ...state, courierError: { ...state.courierError, ...action.payload } };
      case 'CHANGE_BODY_COLOR':
        return { ...state, colors: action.payload };
      case 'OPEN_DIALOG':
          return { ...state, dialog: {open: true, close: false,id: action.payload.id, title: action.payload.title, message: action.payload.message, onSubmit: action.payload.onSubmit }};
      case 'CLOSE_DIALOG':
            return { ...state, dialog: {open: false, close: true, title: '', message: '', onSubmit: undefined }};
      default:
        throw new Error('No matched action!');
    }
  };
  
  export default reducer;
  