
import posthog from "posthog-js";
export const logout = (dispatch) => {
  posthog.reset()
  dispatch({ type: 'RESET_SHIPPING_ERROR'})
  dispatch({ type: 'RESET_CREATE_EDIT_PLAN'})
  dispatch({ type: 'RESET_COURIER_DETAILS'})
  dispatch({ type: 'RESET_NEW_SHIPPING'})
  dispatch({ type: 'RESET_NEW_INVOICE'})
  dispatch({ type: 'RESET_DISCOUNT'})
  dispatch({ type: 'RESET_CREATE_EDIT_RULE'})
  dispatch({ type: 'RESET_USER_PROFILE'})
  dispatch({ type: 'RESET_CONTACT_FORM'})
  dispatch({ type: 'RESET_REQUEST'})
  dispatch({ type: 'RESET_PLAN_ERROR'})
  dispatch({ type: 'RESET_SHIPPING_ERROR'})
  dispatch({ type: 'RESET_DISCOUNT_ERROR'})
  dispatch({ type: 'RESET_USER_ERROR'})
  dispatch({ type: 'END_LOADING' })
  dispatch({ type: 'UPDATE_COURIER', payload: null});
  dispatch({ type: 'UPDATE_USER', payload: null });
  dispatch({ type: 'UPDATE_PLAN', payload: null });
  dispatch({ type: 'UPDATE_RULE', payload: null });
  dispatch({ type: 'UPDATE_UPDATED_PLAN', payload: null });
  dispatch({ type: 'UPDATE_CURRENT_USER', payload: null });
  //reset everything here
  localStorage.removeItem("currentUser");
  
};

