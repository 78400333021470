import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useValue } from 'context/ContextProvider';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import { isAdmin,isBusiness, isLocalBusiness } from 'services/getUserRole';
import SavingsIcon from '@mui/icons-material/Savings';
export const TopUpNow = ({align}) => {
    
    const theme = useTheme();
    const {
        state: { currentUser},
        dispatch,
      } = useValue();
    const userText = 'Riduci il costo della spedizione fino al 10%'
    const businessText = 'Riduci ulteriormente il costo della spedizione fino al 10%'
    const isUserBusiness = isBusiness(currentUser) || isLocalBusiness(currentUser)
  return (
        <Box sx={{display:'flex', justifyContent:align, alignItems:'center',gap:1, p:3, }}>
            <Typography variant='subtitle2' sx={{color:theme.palette.grey.main}}>
                {currentUser && isUserBusiness ? businessText : userText}
            </Typography>
            <Button variant='text' component={Link} to={'/top-up'} startIcon={<SavingsIcon />}>
                    Ricarica il tuo NDR Wallet
            </Button>
        </Box>
  )
}
    