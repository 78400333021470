import axios from 'axios'
import {AuthHeader} from 'services/AuthHeader';


export const AuthApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

export const authLogin = async (form_data) => {
    AuthApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await AuthApi.post("/auth/login", form_data)
    return response.data
  }

export const authRegister = async (user) => {
    AuthApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await AuthApi.post("/auth/signup", user)
    return response.data
}

export const verifyEmail = async (token) => {
    AuthApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await AuthApi.post("/auth/verification/", token )
    return response.data
}

export const sendResetEmail = async (reset) => {
    AuthApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await AuthApi.post("/auth/send-reset-email/", reset )
    return response.data
}

export const inviteUserEmail = async (reset) => {
    AuthApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await AuthApi.post("/auth/invite-user-email/", reset )
    return response.data
}


export const resetPassword = async (reset) => {
    AuthApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await AuthApi.post("/auth/reset-password/", reset )
    return response.data
}

export const resendEmail = async (token) => {
    AuthApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await AuthApi.post("/auth/resend-email/", token )
    return response.data
}