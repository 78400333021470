import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useValue } from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Typography,
  Divider,
  Grid,
  IconButton,
  Collapse,
  Switch
} from '@mui/material';


export const CookieRow = ({field}) => {
  const theme = useTheme();
  const {
    state: { cookieSettings},
    dispatch,
  } = useValue();

  const [open, setOpen] = useState(false)

  const handleUpdateCookieSettings = (e) =>{
    dispatch({type:'UPDATE_COOKIE_SETTINGS', payload: {...cookieSettings, [e.target.name]:Boolean(e.target.checked)}})
  }
  const essentials = <Typography variant='caption' sx={{color:theme.palette.grey.light}}>Questi strumenti di tracciamento sono strettamente necessari per garantire il funzionamento e la fornitura del servizio che ci hai richiesto e, pertanto, non richiedono il tuo consenso.</Typography>
  const functional = <Typography variant='caption' sx={{color:theme.palette.grey.light}}>Questi strumenti di tracciamento abilitano semplici interazioni e funzionalità che ti permettono di accedere a determinate risorse del nostro servizio e ti consentono di comunicare più facilmente con noi..</Typography>
  const experience = <Typography variant='caption' sx={{color:theme.palette.grey.light}}>Questi strumenti di tracciamento ci permettono di migliorare la qualità della tua esperienza utente e consentono le interazioni con piattaforme, reti e contenuti esterni..</Typography>
  const stats = <Typography variant='caption' sx={{color:theme.palette.grey.light}}>Questi strumenti di tracciamento ci permettono di misurare il traffico e analizzare il tuo comportamento per migliorare il nostro servizio.</Typography>
  const marketing = <Typography variant='caption' sx={{color:theme.palette.grey.light}}>Questi strumenti di tracciamento ci permettono di fornirti contenuti marketing o annunci personalizzati e di misurarne la performance.</Typography>

  const cookieText = {essentials: essentials, functional: functional, experience:experience,stats: stats,marketing: marketing}
  return (
       <Grid container spacing={2}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}> 
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:1}}>
                    <Typography variant={'h5'} sx={{color:theme.palette.grey.main}}>
                        {field === 'essentials' ? 'Essenziali' : field ==='functional' ? 'Funzionalità' : field === 'experience' ? 'Esperienza' : field === 'stats' ? 'Statistiche' : field ==='marketing' ? 'Marketing' : 'Non contemplato'}
                    </Typography>
                    <IconButton aria-label="toggle order asc" onClick={()=>setOpen(prev => !prev)}>
                        {!open ? <KeyboardArrowDownIcon fontSize='small'/> : <KeyboardArrowUpIcon fontSize='small'/>}
                    </IconButton>
                </Box>
                <Switch checked={cookieSettings[field]} onChange={(e)=>handleUpdateCookieSettings(e)} size='large' name={field} disabled={field ==='essentials'}/>
            </Grid>
             
            <Grid item xs={12}>
                <Collapse in={open}>
                    {cookieText[field]}
                </Collapse>
            </Grid>
       </Grid>
        );
}
