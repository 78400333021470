import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
} from '@mui/material';
import instagramLogo from "assets/logo/instagram_logo.svg"
import facebookLogo from "assets/logo/facebook_logo.svg"
import twitterLogo from "assets/logo/twitter_logo.svg"
import ReactCountryFlag from "react-country-flag"
import { NdrHorizontalBlack } from 'components/logo/NdrHorizontalBlack';
export const LogoAndSocial = () => {
    const theme = useTheme();

    

  return (
    <Box >
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:2, zIndex:-1}}>
        <Box 
        sx={{
        maxWidth:150,
        }}>
            <Box sx={{filter:`invert(23%) sepia(0%) saturate(2480%) hue-rotate(316deg) brightness(95%) contrast(83%)` }} >
                <a href="/" title="Back to Home Page" >
                    <NdrHorizontalBlack/>
                </a>
            </Box>
        </Box>
        </Box>
        <Box sx={{mt:2}}>
          
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:2}}>
            <Typography variant='subtitle1' sx={{color:theme.palette.grey.main}}>
              NDR SRL, P.IVA 02486460443
            </Typography>
            <ReactCountryFlag countryCode={'IT'} svg />
            </Box>
         
        </Box>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:1, mt:2}}>
          <Box 
          sx={{
          maxWidth:40,
          }}>
              <Box sx={{filter:`invert(23%) sepia(0%) saturate(2480%) hue-rotate(316deg) brightness(95%) contrast(83%)`}} >
                  <a href="https://www.instagram.com/ndrspedizioni/" target='_blank' >
                  <img
                      src={instagramLogo}
                      alt="Visita il nostro profilo instagram"
                      width={'100%'}/>
                  </a>
              </Box>
          </Box>
          <Box 
          sx={{
          maxWidth:40,
          }}>
              <Box sx={{filter:`invert(23%) sepia(0%) saturate(2480%) hue-rotate(316deg) brightness(95%) contrast(83%)`}}>
                  <a href="https://www.facebook.com/ndrspedizioni/" target='_blank'  >
                  <img
                      src={facebookLogo}
                      alt="Visita il nostro profilo facebook"
                      width={'100%'}/>
                  </a>
              </Box>
          </Box>
          <Box 
          sx={{
          maxWidth:40,
          }}>
              <Box sx={{filter:`invert(23%) sepia(0%) saturate(2480%) hue-rotate(316deg) brightness(95%) contrast(83%)`}}>
                  <a href="https://www.twitter.com" target='_blank' >
                  <img
                      src={twitterLogo}
                      alt="Visita il nostro profilo twitter"
                      width={'100%'}/>
                  </a>
              </Box>
          </Box>
        </Box>
    </Box>
  )
}
