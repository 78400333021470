import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { useEffect, useState } from "react";
import { getPickUpDates } from './getPickUpDates';
import { useTheme } from '@mui/material/styles';
import {
    Typography,
    Box,
} from '@mui/material';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const CountDownTimer = () => {
    const theme = useTheme();
    dayjs.locale('it');
    const calculateTimeLeft = () => {
        const firstDate = dayjs(getPickUpDates()[0]);
        const year = firstDate.year().toString();
        let month = firstDate.month() + 1; // Day.js months are 0-indexed
        let day = firstDate.date().toString();
        day = day.length < 2 ? '0' + day : day;
        month = month.toString().length < 2 ? '0' + month : month.toString();
        const hours = '12';
        const minutes = '00';
        const seconds = '00';
        let eventTime = dayjs(year + '/' + month + '/' + day + ' ' + hours + ':' + minutes, "YYYY/MM/DD HH:mm");
        let currentTime = dayjs();
        let duration = dayjs.duration(eventTime.diff(currentTime));
        let interval = 1000;
        if (duration.asMilliseconds() <= 0) {
            clearInterval(interval);
        }
        return ({
            days: duration.days(),
            hours: duration.hours(),
            minutes: duration.minutes(),
            seconds: duration.seconds(),
            shippingDate: eventTime
        });
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', gap: 1, backgroundColor: 'rgba(0,0,0,0.5)', p: 2, borderRadius: 30, minWidth: 580 }}>
            <Typography variant={'subtitle2'} sx={{ color: theme.palette.backGround.light }}>
                Per effettuare una spedizione entro
                <span style={{ color: 'white' }}>{' ' + timeLeft.shippingDate.format('dddd') + ' ' + timeLeft.shippingDate.format('DD') + ' '}</span>
                hai ancora
                <span style={{ color: 'white' }}>{' ' + (timeLeft.days > 0 ? timeLeft.days : '')}</span>
                {timeLeft.days === 1 ? ' giorno ' : timeLeft.days > 0 ? ' giorni ' : ' '}
                <span style={{ color: 'white' }}>{timeLeft.hours}</span>
                {timeLeft.hours === 1 ? ' ora ' : ' ore '}
                <span style={{ color: 'white' }}>{timeLeft.minutes}</span>
                {timeLeft.minutes === 1 ? ' minuto ' : ' minuti '}
                <span style={{ color: 'white' }}>{timeLeft.seconds}</span>
                {timeLeft.seconds === 1 ? ' secondo ' : ' secondi '}
            </Typography>
        </Box>
    );
};
