import React, {useState, useRef, useEffect} from 'react'
import { useValue} from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import SendIcon from '@mui/icons-material/Send';
import {useMutation, useQueryClient, useQuery} from '@tanstack/react-query'
import { resetPassword } from 'features/auth/authApi';
import { PasswordChange } from 'features/profile/components/PasswordChange';
import { Helmet } from 'react-helmet';
import {
    Grid,
    Typography,
    Button,
    Box,
    Container,
  } from '@mui/material';
import { Home } from '@mui/icons-material';
import { Link , useParams, useNavigate} from 'react-router-dom';
import {AccessMessage} from 'components/protected/AccessMessage';
export const ResetPassword = () => {   
    let {token}  = useParams();
    const theme = useTheme();
    const navigate = useNavigate()
    const {
        state: {loading, currentUser,colors,userError, userProfile},
        dispatch,
      } = useValue();
    const queryClient = useQueryClient();

    const {mutate , isLoading, error} = useMutation(resetPassword, {
        onMutate: () =>{
            dispatch({ type: 'START_LOADING' });
        },
        onError: (data)=> {
          dispatch({
            type: 'UPDATE_ALERT',
              payload: {
                open: true,
                severity: 'error',
                message:<div>{data.response.data.detail}</div>
              },}) 
          dispatch({ type: 'END_LOADING' }) },

        onSuccess: (data) => {
          dispatch({
            type: 'UPDATE_ALERT',
              payload: {
                open: true,
                severity: 'success',
                message: data,
              },})   
        },
        onSettled: () => {
        dispatch({ type: 'END_LOADING' })
           //navigate to home with timer
           navigate("/home");
          }
      });

    const handleResetPassword = ()=>{
        mutate({password: userProfile.newPassword, token:token})
    }

    useEffect(()=>{
        if (isLoading && !loading){
            dispatch({ type: 'START_LOADING' })
        }
    },[loading, isLoading])

  return (
            <Container sx={{minHeight:'100vh', pb:10, pt:10}}>
              <Helmet>
                  <title>Reimposta la Tua Password | NDR Spedizioni</title>
                  <meta name='description' content="Hai dimenticato la tua password? Nessun problema. Usa questa pagina per reimpostare la tua password di NDR Spedizioni e accedi nuovamente al tuo account per gestire le tue spedizioni." />
              </Helmet>
                <Grid container spacing={2}>
                {!currentUser ? <>
                    <Grid item xs={12}>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',gap:1, mb:5}}>
                            <Typography variant='h3' sx={{color:theme.palette.grey.main}}>
                               Reimposta la password
                            </Typography>
                        </Box>
                    </Grid>
                   <Grid item xs={12}>
                        <PasswordChange isNew={true} />
                   </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Button variant={'outlined'} size='large' component={Link} to={'/'} sx={{p:2,borderRadius:5}} startIcon={<HomeIcon fontSize='large'/>} color='primary' fullWidth>Torna alla Home</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Button variant='contained' size='large' sx={{p:2,borderRadius:5}} fullWidth startIcon={<SendIcon fontSize='large'/>} onClick={handleResetPassword} color='secondary'
                                disabled={userError.newPassword || userError.passwordConfirm || userProfile.newPassword === '' || userProfile.passwordConfirm === ''} >
                            Reimposta password
                        </Button>
                    </Grid>
                    </> : <AccessMessage message={'Non possiedi i diritti necessari per la visualizzazione di questa pagina'}/>}
                </Grid>
            </Container>
   )
}