import React from 'react';
import LogoXl from 'assets/logo/ndr-horizontal-black/ndr-horizontal-black-1200.png'
import LogoLg from 'assets/logo/ndr-horizontal-black/ndr-horizontal-black-800.png'
import LogoMd from 'assets/logo/ndr-horizontal-black/ndr-horizontal-black-400.png'
import LogoSm from 'assets/logo/ndr-horizontal-black/ndr-horizontal-black-200.png'
import LogoXs from 'assets/logo/ndr-horizontal-black/ndr-horizontal-black-100.png'
export const NdrHorizontalBlack = ({height,width, isLink}) => {
  return (
    <img
        srcSet={`${LogoXs} 100w, ${LogoSm} 200w, ${LogoMd} 400w, ${LogoLg} 800w, ${LogoXl} 1200w,`}
        src = {LogoXl}
        alt="NDR Logo"
        width={width ? width:1200}
        height={height ? height : 312}
        className={isLink && 'image-hover-white'}
    />
  );
};
