import React from 'react';
import LogoXl from 'assets/logo/ndr-horizontal/ndr-horizontal-1200.png'
import LogoLg from 'assets/logo/ndr-horizontal/ndr-horizontal-800.png'
import LogoMd from 'assets/logo/ndr-horizontal/ndr-horizontal-400.png'
import LogoSm from 'assets/logo/ndr-horizontal/ndr-horizontal-200.png'
import LogoXs from 'assets/logo/ndr-horizontal/ndr-horizontal-100.png'
export const NdrHorizontal = ({height, width,isLink}) => {


  return (
    <img
        srcSet={`${LogoXs} 100w, ${LogoSm} 200w, ${LogoMd} 400w, ${LogoLg} 800w, ${LogoXl} 1200w,`}
        src = {LogoXl}
        alt="Spedire online in tutto il mondo"
        width={width ? width:1200}
        height={height ? height : 312}
        className={isLink && 'image-hover-white'}
    />
  );
};
