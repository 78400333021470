export const isAdmin = (user) => {
    return ['admin'].includes(user?.role);
  };

export const isBusiness = (user) => {
    return ['business'].includes(user?.role);
  };

  export const isLocalBusiness = (user) => {
    return ['local_business'].includes(user?.role);
  };

  export const isUser = (user) => {
    return ['user'].includes(user?.role);
  };

  export const isAffiliate = (user) => {
    return ['affiliate'].includes(user?.role);
  };

  