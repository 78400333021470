import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { calculateEaster } from "./calculateEaster";

export const calculateBankHolidays = (date) => {
    dayjs.locale('it');
    const bankHolidays = [
        { day: 1, month: 1 },
        { day: 25, month: 4 },
        { day: 2, month: 6 },
        { day: 15, month: 8 },
        { day: 2, month: 11 },
        { day: 8, month: 12 },
        { day: 25, month: 12 },
        { day: 26, month: 12 },
    ];

    const dateObj = dayjs(date);
    const year = dateObj.year();
    const month = dateObj.month() + 1; // Day.js month is 0-indexed
    const day = dateObj.date();
    const [easterMonth, easterDay] = calculateEaster(year);
    bankHolidays.push({ day: easterDay, month: easterMonth });

    return !bankHolidays.some(holiday => holiday.month === month && holiday.day === day);
};
