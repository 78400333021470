import axios from 'axios'
import {AuthHeader} from 'services/AuthHeader';

export const ShippingApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

export const quoteShipping = async (shipping) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/quote/",shipping)
    return response.data
}

export const quotePublicShipping = async (shipping) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/quote/public",shipping)
    return response.data
}

export const getPricing = async (shipping) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/quote/get-pricing",shipping)
    return response.data
}

export const getDestinations = async (shipping) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/quote/get-destinations",shipping)
    return response.data
}

export const getPricingPrivinces = async (shipping) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/quote/get-pricing-provinces",shipping)
    return response.data
}

export const quoteService = async (service) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/quote/service",service)
    return response.data
}

export const getCapByCity = async (city) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/comuni/capByCity",city)
    return response.data
}

export const getCityByCap = async (cap) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/comuni/cityByCap",cap)
    return response.data
}

export const createShipping = async (shipping) => {
ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/shipping/",shipping)
    return response.data
}

export const addSurcharge = async (surcharge) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
        const response = await ShippingApi.post("/surcharge/",surcharge)
        return response.data
    }

export const stripeCheckOut = async (shippingData) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/stripe/create-checkout-session/",shippingData)
    return response.data
}

export const stripeInvoice = async (invoiceData) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/stripe/invoice/",invoiceData)
    return response.data
}

export const stripeTopUpCheckOut = async (topUpData) => {
    ShippingApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await ShippingApi.post("/stripe/top-up",topUpData)
    return response.data
}

