import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useValue } from 'context/ContextProvider';
import { Icon, IconButton } from '@mui/material';
import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';

export const ConfirmDialog = () => {
  const {
    state: { dialog },
    dispatch,
  } = useValue();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
      <Dialog
        fullScreen={fullScreen}
        open={dialog.open}
        aria-labelledby={dialog.title}
        disableScrollLock={true}
        PaperProps={{
          style: { borderRadius: 20 , padding:10}
        }}>
        <DialogTitle id={dialog.title}>
        {dialog.title}
        </DialogTitle>
        <Box position="absolute" top={0} right={0}>
            <IconButton  aria-label="Close"  onClick={()=>
            dispatch({
              type: 'CLOSE_DIALOG'
            })}>
                <Close/>
            </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText>
        {dialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size='large' sx={{p:2,borderRadius:5}} fullWidth onClick={()=> 
            dispatch({
              type: 'CLOSE_DIALOG'
            })}>
            Annulla
          </Button>
          <Button color="primary" size='large' variant="contained" onClick={dialog.onSubmit} fullWidth autoFocus sx={{p:2,borderRadius:5}}>
            Confermo
          </Button>
        </DialogActions>
      </Dialog>
  );
}
