
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import Dashboard from 'features/admin/Dashboard';
import  {Home} from 'pages/Home';
import {Loading} from 'components/Loading';
import {Notification} from 'components/Notification';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useValue } from 'context/ContextProvider';
import React, { useEffect, Suspense, lazy} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { GlobalStyles } from '@mui/material';
//import { Register } from 'pages/Register';
import { useLoadScript} from "@react-google-maps/api";
import {Box,CircularProgress} from '@mui/material';

const Dashboard = lazy(() => import('features/admin/Dashboard'));
const Register = lazy(() => import('pages/Register').then(module => ({ default: module.Register })));
const libraries = ["places"]
const language = "it"

const App = () => {
 

  const {
    state: {
      colors},
    dispatch,
  } = useValue();
  const  {bodyColor, backgroundColor, isLight, isDarkMode} = colors;

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          h1: {
            fontFamily:'roboto',
            fontSize:'3rem',
            fontWeight:'bold',
            lineHeight:'120%',
            letterSpacing:-1  // Your desired size
            // You can add other styles here as needed
          },
          h2: {
            fontSize:'2.5rem',
            fontFamily:'roboto',
            fontWeight:'bold',
            lineHeight:'120%',
            letterSpacing:-1             // Your desired size
            // You can add other styles here as needed
          },
          h3: {
            fontSize: '2rem',
            fontFamily:'roboto',
            fontWeight:'bold',
            lineHeight:'120%',
            letterSpacing:-1    // Your desired size
            // You can add other styles here as needed
          },
          h4: {
            fontSize: '1.8rem',
            fontFamily:'roboto',
            fontWeight:'bold',
            lineHeight:'120%',
            letterSpacing:-1    // Your desired size
            // You can add other styles here as needed
          },
          h5: {
            fontSize: '1.5em',
            fontFamily:'roboto',
            lineHeight:'120%',
            letterSpacing:-1    // Your desired size
            // You can add other styles here as needed
          },
          h6: {
            fontSize: '1.3rem',
            fontFamily:'roboto',
            lineHeight:'120%',
            letterSpacing:-1    // Your desired size
            // You can add other styles here as needed
          },
        },
        palette: {
          mode:isDarkMode ? 'dark' : 'light',
          secondary: {
            light: '#b2e8cd',
            main: '#25CA8C',
            dark: '#00a250',
            contrastText: '#fff',
           
          },
          primary: {
            main: '#27B4DB',
            light: '#b4e8f8',
            dark: '#21a5c7',
            contrastText: '#fff',
          },
          grey: {
            main: '#424242',
            light: '#a6a6a6',
            dark: '#212121',
            contrastText: '#fff',
          },
          backGround: {
            main: '#f6f6f6',
            light: '#fbfbfb',
            dark: '#efefef',
            contrastText: '#000',
          },
          red: {
            main: '#ff0202',
            light: '#ffcccc',
            dark: '#cc0202',
            contrastText: '#fff',
          },
        },
        components: {
          MuiTableContainer: {
            styleOverrides: {
              root: {
                '@media print': {
                  color: 'red', // Example: changing text color
                  // Other print-specific styles
                }
              }
            }
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                '&:hover': {
                  backgroundColor: 'transparent',
                  color:'#25CA8C',
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                // Override hover styles for the notched outline
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: isLight ? '#b4e8f8' : isDarkMode?  '#f6f6f6' :'#b4e8f8'  , // Directly use the color
                  transition: 'border-color 0.3s ease',
                },
                // Override background color on hover
                /* '&:hover': {
                  backgroundColor: 'rgba(255, 204, 204, 1)', // Directly use the color
                  transition: 'background-color 0.3s ease',
                }, */
                // Set the border width
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '2px',
                },
                
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                // Normal state color
                //color: 'green', // Change to your desired color
                // Focused state color
                /* '&.Mui-focused': {
                  color: 'blue', // Change to your desired color
                }, */
             /*     '&:hover': {
                  color: isLight ? '#b4e8f8' : isDarkMode?  '#f6f6f6' :'#b4e8f8' , // Directly use the color
                  transition: 'color 0.3s ease',
                },  */
              },
            },
          },
         /*  MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: (isLight && !isDarkMode) ? '#b4e8f8' : '#f6f6f6', // Replace with your desired color
                // If you want to make this dynamic based on dark/light mode, you can use:
                // backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#424242' : '#f5f5f5',
              },
            },
          }, */
        },
      }),
    [isDarkMode], );


    const { isLoaded, loadError, } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
      libraries:libraries,
      language:language
    });

    useEffect(()=>{
      isLoaded ? dispatch({type: 'END_LOADING'}) : dispatch({type: 'START_LOADING'})
    },[isLoaded])
  
    if (loadError) dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message:'Errore caricamento script google',
      },
    });

  return (
      <ThemeProvider theme={theme}>
      <CssBaseline />
            <GlobalStyles
              styles={{
                body: { background: isLight ? theme.palette.backGround.light : 'linear-gradient(to right bottom, #27B4DB 14%, #25CA8C 87%)',
                        transition: 'linear-gradient 0.5s ease'
                  },}}/>
      <Loading />
      <Notification />
      <BrowserRouter>
      <Routes>
          <Route path='*' element={<SuspenseWrapper component={Home} />} />
          <Route path='dashboard/*' element={<SuspenseWrapper component={Dashboard} />} />
          <Route path='register' element={<SuspenseWrapper component={Register} />} />
      </Routes>
      </BrowserRouter>
     </ThemeProvider>
    
  );
};

export default App;

function SuspenseWrapper({ component: Component }) {
  return (
    <Suspense fallback={
      <Box sx={{minHeight:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <CircularProgress aria-label="Loading..."/>
      </Box>
    }>
      <Component />
    </Suspense>
  );
}





