import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useValue } from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import {
    InputAdornment,
    TextField,
    Grid,
    IconButton,
    Button,
    Box,
    Tooltip
  } from '@mui/material';
  import ViewInArIcon from '@mui/icons-material/ViewInAr';
  import AddIcon from '@mui/icons-material/Add';
  import DeleteIcon from '@mui/icons-material/Delete';
  import FileCopyIcon from '@mui/icons-material/FileCopy';


export const Packages = () => {
    const theme = useTheme()
    const [errLengthMessage, setErrLengthMessage] = useState('')
    const [errHeightMessage, setErrHeightMessage] = useState('')
    const [errWidthMessage, setErrWidthMessage] = useState('')
    const [errWeightMessage, setErrWeightMessage] = useState('')

    const lengthRef = useRef([]);
    const heightRef = useRef([]);
    const widthRef = useRef([]);
    const weightRef = useRef([]);


    const {
        state: { newShipping, shippingError},
        dispatch,
      } = useValue();
    
      const handleValidation = () =>{
        if (newShipping.shippingRows.length > 0){
          const rows = newShipping.shippingRows.length;
          const length =  Number(lengthRef.current[rows -1].value)
          const height =  Number(heightRef.current[rows -1].value)
          const width =  Number(widthRef.current[rows -1].value)
          const weight = Number(weightRef.current[rows -1].value)
          let lengthErr = false
          let widthErr = false
          let heightErr = false
          let weightErr = false
          if (length<= 0) {
            lengthErr = true
          } else{
            lengthErr = false
          }
          if (height <= 0) {
            heightErr = true
          } else{
            heightErr = false
          }
          if (width <= 0) {
            widthErr = true
          } else{
            widthErr = false
          }
            if (weight <= 0) {
            weightErr = true
          } else {
            if (weight > 2 && newShipping.packageType === 'bag'){
              weightErr = true
            } else {
              weightErr = false
            }
          }
          dispatch({ type: 'UPDATE_SHIPPING_ERROR', payload: {length: lengthErr, height: heightErr, width: widthErr, weight:weightErr}})
          if (shippingError.length || shippingError.height || shippingError.width || shippingError.weight){
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      }

      const handleAddField = (index) => {
          //adds row to state
          if (handleValidation()){
            const list = [...newShipping.shippingRows];
            let length = ""
            let height = ""
            let width = ""
            if (newShipping.packageType === 'bag'){
              length = 20
              height = 20
              width = 1
            }
            dispatch({ type: 'CREATE_NEW_SHIPPING', payload: {...newShipping, shippingRows: [...list,{ length: length, height: height, width: width, weight: "" }]}})
          }
      }

      const handleCloneField = (index) => {
        //adds row to state
        if (handleValidation()){
          const list = [...newShipping.shippingRows];
          const lastPackage = list[index]
          dispatch({ type: 'CREATE_NEW_SHIPPING', payload: {...newShipping, shippingRows: [...list,lastPackage]}})
        }
    }

      const handleDeleteField = (index) => {
        const list = [...newShipping.shippingRows];
        list.splice(index, 1);
        dispatch({ type: 'UPDATE_SHIPPING_ERROR', payload: {weight: false, length:false, width:false, height: false}})
        dispatch({ type: 'CREATE_NEW_SHIPPING', payload: {...newShipping, shippingRows: list}})
      }
    
      const handleFieldsChange = (e, index) => {
        const { name, value } = e.target;
        
        const list = [...newShipping.shippingRows];
        list[index][name] = value;
        dispatch({ type: 'CREATE_NEW_SHIPPING', payload: {...newShipping, shippingQuotes: [], shippingRows: list}})
        handleValidation();
      }

     
    
  return (
    <Grid container spacing={1}>        
        {newShipping.shippingRows.length === 0 && (
            <Grid item xs={12}>
                <Button aria-label="add more" variant='text' onClick={handleAddField(0)} startIcon={<AddIcon />}>
                    Aggiungi collo
                </Button>
            </Grid>
        )}
        {newShipping.shippingRows.length > 0 && newShipping.shippingRows.map((field, index) => {
            return (
            <Grid item xs={12}  key={index}>
            <Grid container spacing={1}>           
            
                <Grid item xs={12} sm={3} md={2}>
                <TextField
                            name="length"
                            label="Lunghezza"
                            id="length"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                              sx: { borderRadius:5 }
                              }}
                              variant="outlined"
                              type="number"
                              disabled={newShipping.packageType === 'bag'}
                              size="large"
                              value={field.length}
                              onChange={(e) => handleFieldsChange(e, index)}
                              error={newShipping.shippingRows.length -1 === index && shippingError.length}
                              helperText={newShipping.shippingRows.length -1 === index && errLengthMessage}
                              inputRef={el => lengthRef.current[index] = el}
                              fullWidth
                              />
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                <TextField
                            name="height"
                            label="Altezza"
                            id="height"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                              sx: { borderRadius:5 }
                              }}
                              variant="outlined"
                              type="number"
                              size="large"
                              disabled={newShipping.packageType === 'bag'}
                              value={field.height}
                              onChange={(e) => handleFieldsChange(e, index)}
                              error={newShipping.shippingRows.length -1 === index && shippingError.height}
                              helperText={newShipping.shippingRows.length -1 === index && errHeightMessage}
                              inputRef={el => heightRef.current[index] = el}
                              fullWidth
                              />
                  </Grid>
                  <Grid item xs={12} sm={3} md={2}>
                  <TextField
                            name="width"
                            label="Larghezza"
                            id="width"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                              sx: { borderRadius:5 }
                              }}
                              variant="outlined"
                              size="large"
                              type="number"
                              disabled={newShipping.packageType === 'bag'}
                              value={field.width}
                              onChange={(e) => handleFieldsChange(e, index)}
                              error={newShipping.shippingRows.length -1 === index && shippingError.width}
                              helperText={newShipping.shippingRows.length -1 === index && errWidthMessage}
                              inputRef={el => widthRef.current[index] = el}
                              fullWidth
                              />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} >
                    <TextField
                            name="weight"
                            label="Peso"
                            id="weight"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                              sx: { borderRadius:5 }
                              }}
                              variant="outlined"
                              size="large"
                              type="number"
                              value={field.weight}
                              onChange={(e) => handleFieldsChange(e, index)}
                              error={newShipping.shippingRows.length -1 === index && shippingError.weight}
                              helperText={newShipping.shippingRows.length -1 === index && errWeightMessage}
                              inputRef={el => weightRef.current[index] = el}
                              fullWidth
                              />
                        
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} sx={{ display:'flex', alignItems:'center', justifyContent:{xs:'center',sm:'flex-start'}, gap:1}}>
                          {newShipping.shippingRows.length - 1 === index && (
                              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                  <Button aria-label="add more" variant='text' onClick={handleAddField} startIcon={<AddIcon />}>
                                    Aggiungi collo
                                  </Button>
                                  <Tooltip title={<div>Clona collo</div>}>
                                      <IconButton aria-label="clone-package" variant='text' onClick={()=>handleCloneField(index)} sx={{color:theme.palette.primary.main}}> 
                                          <FileCopyIcon fontSize='small' />
                                      </IconButton>
                                  </Tooltip>
                              </Box>
                             
                          )}
                          {newShipping.shippingRows.length - 1 === index && index !== 0 &&  (
                               <Tooltip title={<div>Elimina collo</div>}>
                                    <IconButton color="error" aria-label="delete" onClick={() => handleDeleteField(index)}>
                                      <DeleteIcon/>
                                    </IconButton>
                              </Tooltip>
                          )}
                        </Grid>              
                    </Grid>
                    </Grid>
                  );
                }) }   
          </Grid>
  )
}