import React from 'react'
import { useValue } from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import MailIcon from '@mui/icons-material/Mail';

import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';

export const PackageType = ({packageType}) => {
    const theme = useTheme();
    const {
      state: { newShipping},
      dispatch,
    } = useValue();
    const handleTypeChange = (e) => {
       
        let shippingRows = newShipping.shippingRows
        if (packageType === 'bag') {
            shippingRows = [{length:20,height:20,width:1,weight:''}]
        }
        dispatch({type: 'CREATE_NEW_SHIPPING', payload: {...newShipping, packageType: packageType, shippingRows: shippingRows}})
    }

  const label = {box: 'Collo' ,bag: 'Busta',pallet: 'Pallet'}
  return (
    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Tooltip title ={label[packageType]}>
            <IconButton onClick={handleTypeChange}>
                {packageType === 'box' && (
                    <ViewInArIcon fontSize='large' sx={{color:newShipping.packageType === packageType ? theme.palette.secondary.main : theme.palette.grey.main , ':hover':{color:theme.palette.secondary.main}}} />
                )}
                {packageType === 'bag' && (
                    <MailIcon fontSize='large' sx={{color:newShipping.packageType === packageType ? theme.palette.secondary.main : theme.palette.grey.main , ':hover':{color:theme.palette.secondary.main}}} />
                )}
                {packageType === 'pallet'&& (
                    <ViewStreamIcon fontSize='large' sx={{color:newShipping.packageType === packageType ? theme.palette.secondary.main : theme.palette.grey.main , ':hover':{color:theme.palette.secondary.main}}} />
                )}
            </IconButton>
        </Tooltip>
    </Box>
  )
}
