
import axios from 'axios'
import {AuthHeader} from 'services/AuthHeader';

export const BalanceApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

export const getUserBalance = async(id)=> { 
    BalanceApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await BalanceApi.get(`/balance/${id}`)
    return response.data
}

export const getTopUpSizes = async()=> { 
    BalanceApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await BalanceApi.get(`/balance/sizes/`)
    return response.data
}

export const payWithBalance = async (topUp) => {
    BalanceApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await BalanceApi.post("/balance/pay/",topUp)
    return response.data
}

export const payInvoiceWithBalance = async (topUp) => {
    BalanceApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await BalanceApi.post("/balance/pay-invoice/",topUp)
    return response.data
}

export const getTopUpsByUserId = async(id, payment_status, startDate, endDate)=> { 
    BalanceApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await BalanceApi.get(`/balance/top-up/${id}`,  {params: { payment_status: payment_status, startDate: startDate, endDate: endDate}})
    return response.data
}

export const getTopUpByTempId = async(tempId)=> { 
    BalanceApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await BalanceApi.get(`/balance/temp/${tempId}`)
    return response.data
}

export const updateTopupTempId = async (id) => {
    BalanceApi.defaults.headers["Authorization"] = AuthHeader();
    return await  BalanceApi.put(`/balance/temp-id/${id}`);
  };

export const updateTopUpPaymentStatus = async (topUp) => {
    BalanceApi.defaults.headers['Authorization'] = AuthHeader();
    return await BalanceApi.put(`/balance/temp/${topUp.tempId}`,topUp)
}

export const createTopUp = async (topUp) => {
    BalanceApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await BalanceApi.post("/balance/top-up/",topUp)
    return response.data
}

export const deleteTopUp = async ({top_up_id}) => {
    BalanceApi.defaults.headers['Authorization'] = AuthHeader();
    return await BalanceApi.delete(`/balance/${top_up_id}`, top_up_id)
}
