import React , {useEffect, Suspense,useState, lazy} from 'react';
import Login from 'features/auth/Login'
import NavBar from 'components/NavBar'
import posthog  from 'posthog-js';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useValue } from 'context/ContextProvider';
import { CookieBanner } from 'features/cookies/compenents/CookieBanner';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { AdvancedCookieBanner } from 'features/cookies/compenents/AdvencedCookieBanner';
import { EmailVerification } from 'pages/EmailVerification';
import { ResetPassword } from 'pages/ResetPassword';
import { Footer } from 'pages/Footer';
import { CircularProgress } from '@mui/material';
import {Box,} from '@mui/material';
import { Default } from './Default';

// Lazy-loaded components
const Contacts = lazy(() => import('pages/Contacts').then(module => ({ default: module.Contacts })));
const Results = lazy(() => import('features/shipping/components/Results').then(module => ({ default: module.Results })));
const Completed = lazy(() => import('./Completed').then(module => ({ default: module.Completed })));
const Prices = lazy(() => import('pages/Prices').then(module => ({ default: module.Prices })));
const Destinations = lazy(() => import('./Destinations').then(module => ({ default: module.Destinations })));
const Partners = lazy(() => import('./Partners').then(module => ({ default: module.Partners })));
const MyShippings = lazy(() => import('./MyShippings').then(module => ({ default: module.MyShippings })));
const MyTickets = lazy(() => import('./MyTickets').then(module => ({ default: module.MyTickets })));
const MyInvoice = lazy(() => import('./MyInvoice').then(module => ({ default: module.MyInvoice })));
const MyInvoices = lazy(() => import('./MyInvoices').then(module => ({ default: module.MyInvoices })));
const MyShipping = lazy(() => import('./MyShipping').then(module => ({ default: module.MyShipping })));
const Profile = lazy(() => import('./Profile').then(module => ({ default: module.Profile })));
const Support = lazy(() => import('./Support').then(module => ({ default: module.Support })));
const Faq = lazy(() => import('./Faq').then(module => ({ default: module.Faq })));
const Ticket = lazy(() => import('pages/Ticket').then(module => ({ default: module.Ticket })));
const UpgradeToBusiness = lazy(() => import('./UpgradeToBusiness').then(module => ({ default: module.UpgradeToBusiness })));
const UpgradeRequest = lazy(() => import('./UpgradeRequest').then(module => ({ default: module.UpgradeRequest })));
const MyTopUps = lazy(() => import('./MyTopUps').then(module => ({ default: module.MyTopUps })));
const TopUp = lazy(() => import('./TopUp').then(module => ({ default: module.TopUp })));
const TopUpCompleted = lazy(() => import('./TopUpCompleted').then(module => ({ default: module.TopUpCompleted })));
const InvoicePaymentCompleted = lazy(() => import('./InvoicePaymentCompleted').then(module => ({ default: module.InvoicePaymentCompleted })));
const UsersAddressBook = lazy(() => import('./UsersAddressBook').then(module => ({ default: module.UsersAddressBook })));
const NewUserContact = lazy(() => import('./NewUserContacts').then(module => ({ default: module.NewUserContact })));
const Tracking = lazy(() => import('./Tracking').then(module => ({ default: module.Tracking })));
const Resources = lazy(() => import('./Resources').then(module => ({ default: module.Resources })));
const CookiePolicy = lazy(() => import('./CookiePolicy').then(module => ({ default: module.CookiePolicy })));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy').then(module => ({ default: module.PrivacyPolicy })));
const Goods = lazy(() => import('./Goods').then(module => ({ default: module.Goods })));
const Terms = lazy(() => import('./Terms').then(module => ({ default: module.Terms })));
const HowToBoxing = lazy(() => import('./HowToBoxing').then(module => ({ default: module.HowToBoxing })));
const OnlineShipping = lazy(() => import('./OnlineShipping').then(module => ({ default: module.OnlineShipping })));
const ShippingCost = lazy(() => import('./ShippingCost').then(module => ({ default: module.ShippingCost })));
const TopUpWallet = lazy(() => import('./TopUpWallet').then(module => ({ default: module.TopUpWallet })));

export const Home = () => {

  const location = useLocation();
  // //background: isLight ?  `${theme.palette.backGround.light}` :`linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main}`, width: '100%'}}>

    const {
        state: { currentUser, colors},
        dispatch,
      } = useValue();
      const [isLcpLoaded, setIsLcpLoaded] = useState(false);
      useEffect(() => {
        // Function to set isLcpLoaded to true
        const handleLoad = () => {
            setIsLcpLoaded(true);
        };

        // Add event listener
        window.addEventListener('load', handleLoad);

        // Remove event listener on cleanup
        return () => window.removeEventListener('load', handleLoad);
    }, []);

      useEffect(() => {
        // execute on location change
        if (location.pathname === '/results' || 
        location.pathname ==='/myshippings' ||
        location.pathname.includes('/myshipping') ||
        location.pathname.includes('/faq') ||
        location.pathname.includes('/ticket') ||
        location.pathname.includes('/my-tickets') ||
        location.pathname.includes('/my-invoice') ||
        location.pathname.includes('/reset') ||
        location.pathname.includes('/verification') ||
        location.pathname.includes('/completed') ||
        location.pathname.includes('/upgrade-request') ||
        location.pathname.includes('/top-up-completed') ||
        location.pathname.includes('/invoice-completed') ||
        location.pathname.includes('/tracking') ||
        location.pathname.includes('/cosa-posso-spedire') ||
        location.pathname.includes('/spedire-online') ||
        location.pathname.includes('/risparmia-sulla-spedizione') ||
        location.pathname.includes('/costo-spedizione') ||
        location.pathname.includes('/come-imballare') ||
        location.pathname === '/my-top-ups' ||
        location.pathname === '/risorse' ||
        location.pathname === '/top-up' ||
        location.pathname === '/my-invoices' ||
        location.pathname === '/prices' ||
        location.pathname === '/destinations' ||
        location.pathname === '/register' ||
        location.pathname === '/partners' ||
        location.pathname === '/address-book' ||
        location.pathname === '/new-contact' ||
        location.pathname === '/cookie-policy' ||
        location.pathname === '/note-legali' ||
        location.pathname === '/privacy-policy' ||
        location.pathname === '/profile'){
          dispatch({ type: 'CHANGE_BODY_COLOR', payload: {...colors, isLight: true, isDarkMode: false}})
        }else {
          dispatch({ type: 'CHANGE_BODY_COLOR', payload: {...colors, isLight: false, isDarkMode: false}})
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location]);

return (<>
<NavBar/>
      <Login/>
      <ConfirmDialog/>
      <Routes>
          <Route path='*' element={<SuspenseWrapper component={Default} />}/>  
          <Route path='results' element={<SuspenseWrapper component={Results} />} />
          <Route path='contacts' element={<SuspenseWrapper component={Contacts} />} />
          <Route path='prices' element={<SuspenseWrapper component={Prices} />} />
          <Route path='partners' element={<SuspenseWrapper component={Partners} />} />
          <Route path='destinations' element={<SuspenseWrapper component={Destinations} />} />
          <Route path='cookie-policy' element={<SuspenseWrapper component={CookiePolicy} />} />
          <Route path='privacy-policy' element={<SuspenseWrapper component={PrivacyPolicy} />} />
          <Route path='note-legali' element={<SuspenseWrapper component={Terms} />} />
          <Route path='cosa-posso-spedire' element={<SuspenseWrapper component={Goods} />} />
          <Route path='spedire-online' element={<SuspenseWrapper component={OnlineShipping} />} />
          <Route path='risparmia-sulla-spedizione' element={<SuspenseWrapper component={TopUpWallet} />} />
          <Route path='costo-spedizione' element={<SuspenseWrapper component={ShippingCost} />} />
          <Route path='come-imballare' element={<SuspenseWrapper component={HowToBoxing} />} />
          <Route path='support' element={<SuspenseWrapper component={Support} />} />
          <Route path='faq' element={<SuspenseWrapper component={Faq} />} />
          <Route path='ticket' element={<SuspenseWrapper component={Ticket} />} />
          <Route path='top-up' element={<SuspenseWrapper component={TopUp} />} />
          <Route path='my-top-ups' element={<SuspenseWrapper component={MyTopUps} />} />
          <Route path='my-tickets' element={<SuspenseWrapper component={MyTickets} />} />
          <Route path='upgrade' element={<SuspenseWrapper component={UpgradeToBusiness} />} />
          <Route path='upgrade-request' element={<SuspenseWrapper component={UpgradeRequest} />} />
          <Route path='my-invoice/:invoiceId' element={<SuspenseWrapper component={MyInvoice} />} />
          <Route path='my-invoices' element={<SuspenseWrapper component={MyInvoices} />} />
          <Route path='address-book' element={<SuspenseWrapper component={UsersAddressBook} />} />
          <Route path='new-contact' element={<SuspenseWrapper component={NewUserContact} />} />
          <Route path='myshipping/:shippingId' element={<SuspenseWrapper component={MyShipping} />} />
          <Route path='myshipping/*' element={<SuspenseWrapper component={MyShipping} />} />
          <Route path='myshippings' element={<SuspenseWrapper component={MyShippings} />} />
          <Route path='profile' element={<SuspenseWrapper component={Profile} />} />
          <Route path="completed/:shippingId/:success" element={<SuspenseWrapper component={Completed} />} />
          <Route path="top-up-completed/:topUpId/:success" element={<SuspenseWrapper component={TopUpCompleted} />} />
          <Route path="invoice-completed/:invoiceId/:success" element={<SuspenseWrapper component={InvoicePaymentCompleted} />} />
          <Route path='tracking/:trackingNumber?' element={<SuspenseWrapper component={Tracking} />} />
          <Route path='risorse' element={<SuspenseWrapper component={Resources} />} />
          <Route path='verification/:token' element={<SuspenseWrapper component={EmailVerification} />} />
          <Route path='reset/:token' element={<SuspenseWrapper component={ResetPassword} />} />
      </Routes>
      <SuspenseWrapper component={Footer} />
        {isLcpLoaded && (
            <>
                {posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing() ? null : 
                    <>
                        <CookieBanner />
                        <AdvancedCookieBanner/>
                    </>
                }
            </>
        )}
      </>
)

}

function SuspenseWrapper({ component: Component }) {
  return (
    <Suspense fallback={
      <Box sx={{minHeight:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <CircularProgress aria-label="Loading..."/>
      </Box>
    }>
      <Component />
    </Suspense>
  );
}