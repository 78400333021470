import React, {useState, useRef, useEffect} from 'react'
import { useValue} from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
    Grid,
    Paper,
    Typography,
    Box,
    Divider,
  } from '@mui/material';
export const ShippingStep = ({title, icon, text, isLast}) => {    
    const theme = useTheme();
    const {
        state: {loading},
        dispatch,
      } = useValue();


  return (
    <Paper 
    sx={{
        flexGrow:1,
      borderRadius:5, p:3,
      ':hover': {
      outline: `5px solid ${theme.palette.secondary.light}`,
      transition: 'outline-width 0.3s linear'
      },}} elevation={10} >
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap:1}}>
                <Typography variant='h6' sx={{color:theme.palette.grey.main}}>
                    {title}
                </Typography>
                {!isLast &&(
                    <NavigateNextIcon />
                )}
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Divider sx={{color:theme.palette.grey.light}}>
            {icon}
            </Divider>
        </Grid>
        <Grid item xs={12}>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Typography paragraph variant='subtitle1' sx={{color:theme.palette.grey.main}}>
                    {text}    
                </Typography>
            </Box>         
        </Grid>
    </Grid>
       </Paper>     
   )
}