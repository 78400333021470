import { Close, Send } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import posthog from 'posthog-js';
import { Link } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import {
  Button,
  Paper,
  Switch,
  FormControlLabel,
  DialogTitle,
  IconButton,
  Typography,
  Grid,
} from '@mui/material';
import React, { useEffect, useState, } from 'react';

import { useValue } from 'context/ContextProvider'


export const CookieBanner = () => {
  const theme = useTheme();

  const {
    state: { openCookieBanner, cookieSettings},
    dispatch,
  } = useValue();
  const acceptCookies  = () =>{
    posthog.opt_in_capturing()
    handleClose()
  }

  const declineCookies = () => {
    posthog.opt_out_capturing()
    handleClose()
  }
  const handleClose = () => {
    dispatch({ type: 'CLOSE_COOKIE_BANNER' });
  };

  const handleUpdateCookieSettings = (e) =>{
    dispatch({type:'UPDATE_COOKIE_SETTINGS', payload: {...cookieSettings, [e.target.name]:Boolean(e.target.checked)}})
  }


  return (
    <Fade appear={false} in={openCookieBanner}>
      <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              zIndex:1000,
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
            }}>
      <DialogTitle sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', gap:1}}>
        <InfoIcon />

            Informativa
       
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: theme.palette.grey.light,
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Grid container spacing={2} sx={{p:2}} >
        <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
            <Typography paragraph variant='caption' >
            Noi e terze parti selezionate utilizziamo cookie e tecnologie simili per scopi tecnici e, previo consenso, anche per funzionalità, esperienza, misurazione e marketing (con annunci personalizzati), come specificato nella nostra politica sui cookie. Il rifiuto del consenso potrebbe limitare alcune funzionalità.

Per quanto riguarda la pubblicità, potremmo utilizzare dati di geolocalizzazione e identificazione del dispositivo per finalità pubblicitarie, inclusi annunci personalizzati, valutazione di annunci e contenuti, analisi del pubblico e sviluppo di prodotti.

Puoi acconsentire o rifiutare in qualsiasi dayjso tramite il pannello delle preferenze.

Utilizza il pulsante "Accetta" o "Rifiuta" o chiudi questa informativa per procedere
            </Typography>
        </Grid>
        {Object.keys(cookieSettings).map((field,index) => {
            return (
              cookieSettings[field] !== null &&
              <Grid item xs={field === 'essentials' ? 12 : 6} sm={field === 'essentials' ? 6 : 3} md={field === 'essentials' ? 4 : 2} key={index}>
                <FormControlLabel control={<Switch checked={cookieSettings[field]} onChange={(e)=>handleUpdateCookieSettings(e)} size='large' name={field} disabled={field==='essentials'}/>} 
                label={field === 'essentials' ? 'Essenziali' : field ==='functional' ? 'Funzionalità' : field === 'experience' ? 'Esperienza' : field === 'stats' ? 'Statistiche' : field ==='marketing' ? 'Marketing' : 'Non contemplato'}/>
              </Grid>
                  )
            })}

        <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
            <Typography component={Link} variant={'subtitle2'} to={'/cookie-policy'}>Leggi Informativa sui cookie</Typography>
        </Grid>
        <Grid item xs={12} sm={4}  md={3} lg={2} xl={2}>
            <Button variant='contained' size='small' color='primary' onClick={()=>dispatch({type:'OPEN_ADVANCED_COOKIE_BANNER'})} sx={{p:1,borderRadius:5}} fullWidth >
                Per saperne di più
            </Button>
        </Grid>
        <Grid item xs={12} sm={2} md={3} lg={6} xl={6} display={{xs:'none', sm:'block', md:'block',lg:'block',xl:'block'}} />
        <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
            <Button variant='outlined' size='small' color='primary' onClick={declineCookies}  sx={{p:1,borderRadius:5}} fullWidth>
                    Rifiuta
            </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
            <Button variant='contained' size='small' color='secondary' onClick={acceptCookies} disabled={!cookieSettings.essentials} sx={{p:1,borderRadius:5}} fullWidth>
                    Accetta
            </Button>
        </Grid>
      </Grid>
    </Paper>
  </Fade>
  );
};
