import React, {useState, useRef, useEffect} from 'react'
import { useValue} from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import { ShippingStep } from './ShippingStep';
import CalculateIcon from '@mui/icons-material/Calculate';
import TuneIcon from '@mui/icons-material/Tune';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
    Grid,
    Link,
    Typography,
    Box,
    Container,
  } from '@mui/material';
import { TopUpNow } from 'components/TopUpNow';
export const ShippingSteps = () => {    
    const theme = useTheme();
    const {
        state: {loading},
        dispatch,
      } = useValue();


  return (
        <>
            <Box sx={{ backgroundColor:{ xs:'none', sm:theme.palette.backGround.light}, }}>
                
                
            
            <Container>
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', color:{xs: theme.palette.backGround.light, sm:theme.palette.grey.main}, pt:2}}>
                    <Typography variant='h2'>
                        Spedisci in 4 semplicissimi step!
                    </Typography>
                </Box>
                <Grid container spacing={2} display={'flex'} alignItems={'stretch'}>
                    <Grid item xs={12} flexGrow={1}>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', mb:3}}>
                            <Typography paragraph variant='subtitle1' sx={{color:{xs: theme.palette.backGround.light, sm:theme.palette.grey.main}}}>
                                Spedire non è mai stato così semplice, segui le nostre istruzioni, sei a pochi click dalla tua spedizione online!
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} display={'flex'}>
                        <ShippingStep title={<span>Quota</span>} icon={<CalculateIcon fontSize='large'/>} 
                        text={'Inserisci lo stato di partenza e di arrivo, sfrutta il nostro servizio di autocompletamento per immettere velocemente gli indirizzi, poi clicca su quota spedizione'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}  display={'flex'}>
                        <ShippingStep title={<span>Confronta</span>} icon={<TuneIcon fontSize='large'/>} 
                        text={'Confronta le nostre tariffe e scegli il trasportatore o la tariffa che più soddisfa le tue esigenze'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} display={'flex'}>
                        <ShippingStep title={<span>Completa</span>} icon={<CreditCardIcon fontSize='large'/>} 
                        text={'Completa il tuo ordine inserendo i dati del mittente, del destinatario, poi processa il pagamento con la carta di credito o con il tuo NDR Wallet'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} display={'flex'}>
                        <ShippingStep title={<span>Attendi il corriere</span>} icon={<LocalShippingIcon fontSize='large'/>} isLast={true} 
                        text={'Controlla la tua email, stampa la lettera di vettura ed applicala sul pacco, il corriere effettuerà il ritiro nel giorno selezionato'}/>
                    </Grid>
                    <Grid item xs={12}>
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', p:3}}>
                        <Link href={'/spedire-online'} title="Back to Home Page" underline="none" variant='subtitle1' color={'white'} sx={{':hover':{ color:theme.palette.grey.main} }}>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                        La mia prima spedizione
                        <NavigateNextIcon />
                        </Box>
                        </Link>
                    </Box>
                    </Grid>
                </Grid>
            </Container>
            </Box>
        </>
   )
}