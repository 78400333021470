import { Mail, Notifications } from '@mui/icons-material';
import { Avatar, Badge, Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useValue } from 'context/ContextProvider';
import UserMenu from './UserMenu';
import { UserNotifications } from 'features/notifications/components/UserNotifications';
import {useMutation, useQueryClient, useQuery} from '@tanstack/react-query'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { getNotificationsByUserId } from 'features/notifications/NotificationsApi';
import { getUserBalance } from 'features/balance/BalanceApi';
import WalletIcon from '@mui/icons-material/Wallet';
import { Link, useNavigate } from 'react-router-dom';
import { isAdmin } from 'services/getUserRole';
import useCheckToken from 'features/auth/useCheckToken';
const UserIcons = () => {
  const navigate = useNavigate();
  const {
    state: { currentUser, loading },
    dispatch,
  } = useValue();
  useCheckToken()
  const [anchorUserMenu, setAnchorUserMenu] = useState(null);
  const [anchorUserNotifications, setAnchorUserNotifications] = useState(null);
  let userId = currentUser?.id || null

  const {isError, isLoading, data, error} = useQuery(
    ['notifications', userId],()=> getNotificationsByUserId(userId),
    { 
      enabled: !!userId,
      onError: (data)=> {
        dispatch({
          type: 'UPDATE_ALERT',
            payload: {
              open: true,
              severity: 'error',
              message: <div>{data.response.data.detail}</div>
            },}) 
        dispatch({ type: 'END_LOADING' }) },
    onSuccess: ()=> {
      dispatch({ type: 'END_LOADING' })},
  }
)
const {isError: balanceIsError, isLoading:balanceIsLoading, data: userBalance, error: balanceError} = useQuery(
  ['userBalance' ,userId],()=> getUserBalance(userId),
  { 
    enabled: !!userId,
    onError: (data)=> {
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: <div>{data.response.data.detail}</div>
          },}) 
      dispatch({ type: 'END_LOADING' }) },
  onSuccess: ()=> {
    dispatch({ type: 'END_LOADING' })},
}
)

  return (
    <Box sx={{display:'flex', alignItems:'center'}}>
       <Tooltip title={<span>Support</span>}>
        <IconButton aria-label="support" color="inherit" onClick={() =>navigate(isAdmin(currentUser) ? '/dashboard/support':'/support')}>
          <SupportAgentIcon />
        </IconButton>
      </Tooltip>
      {isLoading && currentUser ? (<CircularProgress size={20} />):(
      <Tooltip title={<span>Notifiche</span>}>
        <IconButton size="large" aria-label="notifications" color="inherit" onClick={(e) => setAnchorUserNotifications(e.currentTarget)}>
          <Badge color="error" badgeContent={data?.count > 0 ? data.count :null}>
            <Notifications />
          </Badge>
        </IconButton>
      </Tooltip>
      )}
      {balanceIsLoading && currentUser ? (<CircularProgress size={20} />):(
      <>
       { userBalance === null ? (
        <Tooltip title={<span>Ricarica il tuo NDR Wallet</span>}>
          <IconButton aria-label="wallet" size="large" color="inherit" component={Link} to={'/top-up'}>
              <WalletIcon />
          </IconButton>
        </Tooltip>
        ) : (
          <Typography variant='h6'>
            {userBalance.toFixed(2) + ' €'}
          </Typography>
        )}
      </>
      )}
      <Tooltip title={<span>Pannello Utente</span>}>
        <IconButton aria-label="profile" onClick={(e) => setAnchorUserMenu(e.currentTarget)}>
          <Avatar src={currentUser?.photoURL} alt={currentUser?.name}>
            {currentUser?.name?.charAt(0).toUpperCase()}
          </Avatar>
        </IconButton>
      </Tooltip>
      <UserMenu {...{ anchorUserMenu, setAnchorUserMenu }}/>
      {!isLoading  && (
      <UserNotifications notifications={data.notifications} {...{ anchorUserNotifications, setAnchorUserNotifications,  }} />
      )}
    </Box>
  );
};

export default UserIcons;